import React, { useState } from "react";
import { TagsInput } from "react-tag-input-component";
import { IoArrowForward } from "react-icons/io5";

const CategoriesAndServices = () => {
  const [selected, setSelected] = useState([]);

  return (
    <div>
      <h1 className="text-2xl font-bold">Categories and Services</h1>
      <p className="mt-4 leading-8">
        Let customers know what your business offers by selecting categories &
        services. This will help customers find your business, and help you
        generate leads.
      </p>
      <button className="flex items-center gap-1 text-primary mt-4">
        Go to COVID-19 updates
        <IoArrowForward className="text-xl" />
      </button>
      <div className="mt-4">
        <label htmlFor="category" className="text-lg font-bold">
          Select:
        </label>

        <select name="sort" id="sort">
          <option value="option1">Option # 1</option>
          <option value="option2">Option # 2</option>
          <option value="option3">Option # 3</option>
        </select>
      </div>
      <div className="mt-3">
        <div className="flex items-center gap-2 my-2">
          <input type="checkbox" className="w-fit" />
          <p>Desserts</p>
        </div>
        <div className="flex items-center gap-2 my-2">
          <input type="checkbox" className="w-fit" />
          <p>Catering & Supplies</p>
        </div>
        <div className="flex items-center gap-2 my-2">
          <input type="checkbox" className="w-fit" />
          <p>Fast Food & Takeaway</p>
        </div>
        <div className="flex items-center gap-2 my-2">
          <input type="checkbox" className="w-fit" />
          <p>Beverage & Tobacco</p>
        </div>
        <div className="flex items-center gap-2 my-2">
          <input type="checkbox" className="w-fit" />
          <p>Desserts</p>
        </div>
      </div>
      <button className="flex items-center gap-1 text-primary mt-4">
        Professional Services
        <IoArrowForward className="text-xl" />
      </button>
      <div className="mt-4">
        <TagsInput
          value={selected}
          onChange={setSelected}
          name="services"
          placeHolder="Add services"
        />
      </div>
    </div>
  );
};

export default CategoriesAndServices;
