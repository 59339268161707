import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { RiImageAddFill } from "react-icons/ri";

const DashboardImage = () => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="flex justify-center items-center w-full h-full relative">
      <div
        {...getRootProps({ className: "dropzone" })}
        className="w-full h-full flex justify-center items-center z-10"
      >
        <input {...getInputProps()} />
        {!files[0] && <RiImageAddFill className="text-2xl" />}
      </div>
      <aside className="absolute top-0 left-0 w-full h-full object-contain flex justify-center items-center">
        {files[0] && (
          <img
            src={files[0].preview}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(files[0].preview);
            }}
            className="w-full h-full object-contain"
            alt=""
          />
        )}
      </aside>
    </section>
  );
};

export default DashboardImage;
