import React from "react";

const FooterLinks = () => {
  return (
    <div className="flex flex-col gap-6 lg:gap-2 lg:flex-row justify-between py-16 ">
      <div>
        <h1 className="text-2xl font-semibold">Subscribe to Our Newsletter</h1>
        <p className="my-2">
          Get all the latest information, sales and offers.
        </p>
        <div className="bg-white flex justify-between mt-10">
          <input
            type="email"
            className="bg-transparent text-gray-700 focus:outline-none py-4 px-6 w-full"
            placeholder="Enter email here"
          />
          <button className="bg-secondary text-white px-6 h-auto">
            Subscribe
          </button>
        </div>
      </div>
      <div>
        <h1 className="text-2xl font-semibold">About</h1>
        <ul className="mt-4">
          <li className="my-3">
            <a href="/" className="hover:underline">
              What We Do
            </a>{" "}
          </li>
          <li className="my-3">
            <a href="/" className="hover:underline">
              FAQ
            </a>
          </li>
          <li className="my-3">
            <a href="/" className="hover:underline">
              Blog
            </a>
          </li>
          <li className="my-3">
            <a href="/" className="hover:underline">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h1 className="text-2xl font-semibold">For Businesses</h1>
        <ul className="mt-4">
          <li className="my-3">
            <a href="/" className="hover:underline">
              Manage Your Lisitings
            </a>
          </li>
          <li className="my-3">
            <a href="/" className="hover:underline">
              Add a Business
            </a>
          </li>
          <li className="my-3">
            <a href="/" className="hover:underline">
              Advertise on CityLocal 101
            </a>
          </li>
          <li className="my-3">
            <a href="/" className="hover:underline">
              Business Support
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h1 className="text-2xl font-semibold">About</h1>
        <ul className="mt-4">
          <li className="my-3">
            <a href="/" className="hover:underline">
              For Reviewers
            </a>
          </li>
          <li className="my-3">
            <a href="/" className="hover:underline">
              Find a Business
            </a>
          </li>
          <li className="my-3">
            <a href="/" className="hover:underline">
              Search by Category
            </a>
          </li>
          <li className="my-3">
            <a href="/" className="hover:underline">
              Write a Review
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterLinks;
