import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import AccountSettings from "./pages/AccountSettings";
import Search from "./pages/Search";
import IndivBusiness from "./pages/IndivBusiness";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import SetPassword from "./pages/SetPassword";
import ActivateAccount from "./pages/ActivateAccount";
import ForgotPassword from "./pages/ForgotPassword";

import "./index.css";

import store from './redux/store';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';

require('./bootstrap');

const Root = () => {
    //const dispatch = useDispatch;
    //dispatch(rootAction.setAuthUser(null)); //authUser is from blade file
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/account-settings" element={<AccountSettings/>}/>
                <Route path="/search" element={<Search/>}/>
                <Route path="/biz/:slug" element={<IndivBusiness/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Signup/>}/>
                <Route path="/set-password" element={<SetPassword/>}/>
                <Route path="/activate-account" element={<ActivateAccount/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
            </Routes>
        </BrowserRouter>
    )
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode><Provider store={store}><CookiesProvider><Root/></CookiesProvider></Provider></React.StrictMode>);
