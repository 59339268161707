import React from "react";
import ReactPaginate from "react-paginate";

import "./Pagination.css";

const Pagination = () => {
  return (
    <ReactPaginate
      previousLabel="<"
      nextLabel=">"
      breakLabel="..."
      breakClassName={"break-me"}
      pageCount={50}
      containerClassName={"pagination"}
      subContainerClassName={"pages pagination"}
      activeClassName={"active"}
    />
  );
};

export default Pagination;
