import React from "react";
import { IoEarth, IoCall, IoMail, IoStar, IoLocation } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import image from "../../images/featured.png";

const RestaurantListingCard = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col md:flex-row justify-between border border-gray-400 my-8 cursor-pointer"
      style={{ boxShadow: "0 0 10px rgba(0,0,0,16%)" }}
      onClick={() => {
        navigate("/biz/1");
      }}
    >
      <div className="flex-1 h-auto w-auto">
        <img
          src={image}
          alt=""
          className="h-full w-full max-h-72 md:max-h-max object-cover"
        />
      </div>
      <div style={{ flex: 2 }}>
        <div className="p-6">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2">
            <h1 className="text-xl font-bold">The Red Flame Dinner</h1>
            <button className="flex items-center gap-2">
              <IoLocation className="text-lg" />
              <p>Get Directions</p>
            </button>
          </div>

          <div className="flex items-center gap-3 font-semibold my-3">
            <h5>Rating</h5>
            <h5>5.0</h5>
            <div className="flex items-center gap-2">
              <IoStar className="text-yellow text-xl" />
              <IoStar className="text-yellow text-xl" />
              <IoStar className="text-yellow text-xl" />
              <IoStar className="text-yellow text-xl" />
              <IoStar className="text-yellow text-xl" />
            </div>
          </div>
          <p className="my-1 text-sm leading-6">
            It is a long established fact that a reader will be distracted by
            content of a page when looking at its layout. The point of using.
          </p>
          <div className="flex gap-3 mt-4 flex-wrap">
            <button className="flex items-center gap-2 border border-gray-500 py-2 px-4">
              <IoCall />
              Phone Number
            </button>
            <button className="flex items-center gap-2 border border-gray-500 py-2 px-4">
              <IoEarth />
              Website
            </button>
            <button className="flex items-center gap-2 border border-gray-500 py-2 px-4">
              <IoMail />
              Email
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center gap-2 mt-2 border-t py-3 px-6 border-gray-400">
          <button className="flex items-center gap-2">
            <IoLocation className="text-lg" />
            <p>Get Directions</p>
          </button>
          <p>Closed Now!</p>
        </div>
      </div>
    </div>
  );
};

export default RestaurantListingCard;
