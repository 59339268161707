import React from "react";

import { IoStar } from "react-icons/io5";

const IndivReviewCard = ({ name, date, rating, review, stars }) => {
  return (
    <div
      style={{
        boxShadow: "0 0 10px rgba(0,0,0,16%)",
      }}
      className="my-8"
    >
      <div
        className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-6 gap-6"
        style={{
          backgroundColor: "#F5F7FA",
        }}
      >
        <h1 className="text-6xl text-gray-500">{rating}</h1>
        <div className="flex-1">
          <h1 className="text-xl font-semibold">{name}</h1>
          <p>{date}</p>
        </div>
        <div className="flex items-center gap-3 font-semibold">
          <h5>Rating</h5>
          <h5>{rating}</h5>
          <div className="flex items-center gap-2">
            {Array.from({ length: stars }, (index) => (
              <IoStar key={index} className="text-yellow text-xl" />
            ))}
          </div>
        </div>
      </div>
      <div className="px-6 mt-4">
        <p className="leading-8 tracking-wide">{review}</p>
      </div>
      <div className="flex gap-4 p-6 pt-4">
        <button className="text-primary">Approve</button>
        <button className="text-secondary">Remove</button>
      </div>
    </div>
  );
};

export default IndivReviewCard;
