import React, {useState} from "react";
import axiosConfig from "../../helpers/axiosConfig";

// import StarRating from "react-star-rating-component";
import StarRatings from "react-star-ratings/build/star-ratings";

import Button from "../Shared/Button/Button";

import TextArea from "../Shared/TextArea/TextArea";
import TextField from "../Shared/TextField/TextField";

const ReviewForm = ({company}) => {
    const [newRating, setNewRating] = useState(0);
    const [fullName, setFullname] = useState();
    const [email, setEmail] = useState();
    const [text, setText] = useState('');

    function changeRating(newRating, name) {
        setNewRating(newRating)
    }

    const handleSubmit = event => {
        event.preventDefault();
        var json = {
            "company_id": company.id,
            "rating": newRating,
            "review": text,
            "name": fullName,
            "email": email
        };

        axiosConfig.post("/api/web/company/review", json).then((response) => {
            console.log(response);
        })
    }
    return (
        <div className="my-8">
            <h1 className="text-2xl font-bold">Post a review</h1>
            <form className="mt-6" onSubmit={handleSubmit}>
                <TextArea label="Enter Review" id="review" value={text} onChange={event => setText(event.target.value)}/>
                <div className="mt-6 flex justify-between items-center gap-6">
                    <div className="flex-1">
                        <TextField label="Full Name" id="name" type="text" value={fullName} onChange={event => setFullname(event.target.value)}/>
                    </div>
                    <div className="flex-1">
                        <TextField label="Email Address" id="email" type="email" value={email} onChange={event => setEmail(event.target.value)}/>
                    </div>
                </div>
                <div className="mt-14">
                    <div className="flex flex-col md:flex-row md:items-center gap-6 lg:gap-10">
                        <div className="flex flex-col xs:flex-row xs:items-center gap-6 xs:gap-10">
                            <h1 className="text-2xl font-semibold  text-secondary">
                                Your Rating
                            </h1>
                            <div>
                                <StarRatings
                                    rating={newRating}
                                    starRatedColor="#ECC56F"
                                    numberOfStars={5}
                                    changeRating={changeRating}
                                    starDimension="24px"
                                    starSpacing="5px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-6 text-xl">
                            <p>Poor</p>
                            <p>Average</p>
                            <p>Good</p>
                            <p>Excellent</p>
                        </div>
                    </div>
                    <div className="mt-14">
                        <Button type={"submit"} title="Post Review"/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ReviewForm;
