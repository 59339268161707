import React from "react";
import {IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoPinterest, IoLogoTwitter, IoLogoYoutube,} from "react-icons/io5";

const SocialMediaContainer = ({company}) => {
    if (company.social_links.length <= 0) {
        return <></>
    }

    return (
        <div className="border border-gray-400 p-3 xs:p-6 my-6 flex items-center gap-10 flex-wrap">
            {
                company.social_links.facebook ?
                    <a className="text-3xl hover:text-primary" href={company.social_links.facebook} target="_blank">
                        <IoLogoFacebook/>
                    </a>
                    : ''
            }
            {company.social_links.instgram ?
                <button className="text-3xl hover:text-primary">
                    <IoLogoInstagram/>
                </button>
                : ''
            }
            {company.social_links.twitter ?
                <button className="text-3xl hover:text-primary">
                    <IoLogoTwitter/>
                </button>
                : ''
            }
            {company.social_links.pinterest ?
                <button className="text-3xl hover:text-primary">
                    <IoLogoPinterest/>
                </button>
                : ''
            }
            {company.social_links.youtube ?
                <button className="text-3xl hover:text-primary">
                    <IoLogoYoutube/>
                </button>
                : ''
            }
            {company.social_links.linkedin ?
                <button className="text-3xl hover:text-primary">
                    <IoLogoLinkedin/>
                </button>
                : ''
            }
        </div>
    );
};

export default SocialMediaContainer;
