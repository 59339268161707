import React from "react";
import { IoArrowForward } from "react-icons/io5";
import Button from "../../Shared/Button/Button";
import TextField from "../../Shared/TextField/TextField";

const CoronavirusUpdates = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Updates during Coronavirus</h1>
      <p className="my-3 leading-8">
        Give your customers up-to-date information about your hours, services,
        and any new updates about your business.
      </p>
      <button className="flex items-center gap-1 text-primary">
        Go to COVID-19 updates
        <IoArrowForward className="text-xl" />
      </button>

      <div className="mt-6">
        <h1 className="text-lg font-semibold">Safety Measurements</h1>
        <div className="mt-4 flex items-center gap-4 flex-wrap">
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Mask required</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Staff wears mask</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Proof of vaccination required</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>All staff fully vaccinated</p>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <h1 className="text-lg font-semibold">Website Link</h1>
        <div className="mt-4">
          <TextField label="Enter website link" id="website" type="text" />
        </div>
      </div>
      <div className="flex flex-col xs:flex-row xs:gap-4 mt-6">
        <Button
          title="Save & continue"
          style={{ backgroundColor: "#F5F7FA" }}
          className="text-gray-700"
        />
        <Button
          title="Skip for now"
          style={{ backgroundColor: "#F5F7FA" }}
          className="text-gray-700"
        />
      </div>
    </div>
  );
};

export default CoronavirusUpdates;
