import React, { useState } from "react";
import Toggle from "react-toggle";

import "./BusinessHours.css";

const BusinessHours = () => {
  const [isInputChecked, setIsInputChecked] = useState();
  const toggleChangeHandler = () => {
    setIsInputChecked((prevValue) => !prevValue);
  };
  return (
    <div
      className="flex flex-col gap-6 p-6 overflow-y-scroll"
      style={{ maxHeight: "70vh" }}
    >
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        <h1 className="text-lg font-semibold flex-1 w-max">Monday</h1>
        <div className="flex-1 ">
          <Toggle
            onChange={toggleChangeHandler}
            defaultChecked={isInputChecked}
          />
        </div>
        <div className="flex gap-4">
          <input type="time" className="border border-gray-400 px-2 py-1" />
          <input type="time" className="border border-gray-400 px-2 py-1" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        <h1 className="text-lg font-semibold flex-1">Tuesday</h1>
        <div className="flex-1 ">
          <Toggle
            onChange={toggleChangeHandler}
            defaultChecked={isInputChecked}
          />
        </div>
        <div className="flex gap-4">
          <input type="time" className="border border-gray-400 px-2 py-1" />
          <input type="time" className="border border-gray-400 px-2 py-1" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        <h1 className="text-lg font-semibold flex-1">Wednesday</h1>
        <div className="flex-1 ">
          <Toggle
            onChange={toggleChangeHandler}
            defaultChecked={isInputChecked}
          />
        </div>
        <div className="flex gap-4">
          <input type="time" className="border border-gray-400 px-2 py-1" />
          <input type="time" className="border border-gray-400 px-2 py-1" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        <h1 className="text-lg font-semibold flex-1">Thursday</h1>
        <div className="flex-1 ">
          <Toggle
            onChange={toggleChangeHandler}
            defaultChecked={isInputChecked}
          />
        </div>
        <div className="flex gap-4">
          <input type="time" className="border border-gray-400 px-2 py-1" />
          <input type="time" className="border border-gray-400 px-2 py-1" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        <h1 className="text-lg font-semibold flex-1">Friday</h1>
        <div className="flex-1 ">
          <Toggle
            onChange={toggleChangeHandler}
            defaultChecked={isInputChecked}
          />
        </div>
        <div className="flex gap-4">
          <input type="time" className="border border-gray-400 px-2 py-1" />
          <input type="time" className="border border-gray-400 px-2 py-1" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        <h1 className="text-lg font-semibold flex-1">Saturday</h1>
        <div className="flex-1 ">
          <Toggle
            onChange={toggleChangeHandler}
            defaultChecked={isInputChecked}
          />
        </div>
        <div className="flex gap-4">
          <input type="time" className="border border-gray-400 px-2 py-1" />
          <input type="time" className="border border-gray-400 px-2 py-1" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
        <h1 className="text-lg font-semibold flex-1">Sunday</h1>
        <div className="flex-1 ">
          <Toggle
            onChange={toggleChangeHandler}
            defaultChecked={isInputChecked}
          />
        </div>
        <div className="flex gap-4">
          <input type="time" className="border border-gray-400 px-2 py-1" />
          <input type="time" className="border border-gray-400 px-2 py-1" />
        </div>
      </div>
    </div>
  );
};

export default BusinessHours;
