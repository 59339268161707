import React, {useState} from "react";
import {Alert as FAlert} from "flowbite-react";

function infoIcon() {
    return (<>
        <svg aria-hidden="true" className={"flex-shrink-0 w-5 h-5"} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
        </svg>
    </>)
}

const Alert = ({text, title, variant, dismissable, icon, children}) => {
    const [showAlert, setShowAlert] = useState(true);
    let _color = variant;
    let _icon = false;
    if (icon === true || icon === 'true') {
        _icon = infoIcon;
    }

    let _additionalContent = '';
    if (title) {
        _additionalContent = (icon ? <div style={{"margin-left": '24px'}}>text</div> : text);
        text = null;
    }

    switch (variant) {
        case 'danger':
        case 'error':
            _color = 'failure';
            break;
    }

    return (<>
        {showAlert && <FAlert color={'success'} icon={_icon} additionalContent={_additionalContent}
                              onDismiss={dismissable ? function onDismiss() {
                                  setShowAlert(false)
                              } : false}>
            <div className={_icon ? 'ml-1' : ''}>
                {children ? children : <>
                    {' '}
                    {title && <><span className="font-medium"> {title}</span> {' '}</>}
                    <div dangerouslySetInnerHTML={{__html: text}}/>
                </>}
            </div>
        </FAlert>}
    </>);
};

export default Alert;
