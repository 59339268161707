import React from "react";
import { IoArrowForward } from "react-icons/io5";
import { FaMoneyCheckAlt } from "react-icons/fa";
import TextArea from "../../Shared/TextArea/TextArea";
import TextField from "../../Shared/TextField/TextField";
import DealImageUpload from "./DealImageUpload";

const DealsAndPromotions = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Deals & Promotions</h1>
      <div className="mt-4 flex gap-3">
        <FaMoneyCheckAlt className="text-2xl text-primary" />
        <p>To activate this feature please add your billing information.</p>
      </div>
      <button className="flex items-center gap-1 text-primary mt-4">
        Re-send email now
        <IoArrowForward className="text-xl" />
      </button>
      <form className="mt-6 flex flex-col gap-4">
        <TextField label="Deal Title" id="title" type="text" />
        <TextArea label="Deal Description" id="description" />
      </form>
      <div className="mt-6">
        <h2 className="text-xl font-semibold">Add Deal Image</h2>
        <div className="mt-6">
          <DealImageUpload />
        </div>
      </div>
    </div>
  );
};

export default DealsAndPromotions;
