import React from "react";
import {IoCall, IoGlobe, IoLocation} from "react-icons/io5";
import InnerHTML from 'dangerously-set-html-content';

const MapContainer = ({company}) => {
    return (
        <div id="map" className="border border-gray-400">
            <div>
                <InnerHTML html={company.location_map}/>
            </div>
            <div className="p-4 xs:p-6 md:w-min">
                {company.phone && <button className="flex items-center gap-3 my-3 text-left">
                    <IoCall className="text-xl"/>
                    {company.phone}
                </button>}

                {company.website && <button className="flex items-center gap-3 my-3 text-left">
                    <IoGlobe className="text-xl"/>
                    {company.website}
                </button>}
                {company.address && <button className="flex items-center gap-3 my-3 text-left">
                    <IoLocation className="text-xl"/>
                    {company.address}
                </button>}
            </div>
        </div>
    );
};

export default MapContainer;
