import React from "react";
import Button from "../../Shared/Button/Button";
import TextField from "../../Shared/TextField/TextField";
import Upload from "./Upload";

const PhotosAndVideos = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Upload and manage photos</h1>
      <div className="mt-6">
        <h1 className="text-xl font-semibold">Add your photos below</h1>
        <p className="mt-4 leading-8">
          Businesses that upload 4 or more photos often stand out more. Add
          photos to attract more customers.
        </p>
      </div>
      <div className="mt-6">
        <Upload />
      </div>
      <div className="mt-6">
        <h1 className="text-xl font-semibold">Add Caption</h1>
        <div className="mt-4">
          <TextField label="Add caption here" id="captiom" type="text" />
        </div>
      </div>
      <div className="flex flex-col xs:flex-row xs:gap-4 mt-6">
        <Button
          title="Cancel"
          style={{ backgroundColor: "#F5F7FA" }}
          className="text-gray-700"
        />
        <Button title="Save" className="bg-primary" />
      </div>
    </div>
  );
};

export default PhotosAndVideos;
