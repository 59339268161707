import React from "react";
import FeaturedBusinessCard from "./FeaturedBusinessCard";

const FeaturedBusiness = ({related_business}) => {
    related_business = related_business.slice(0, 3);
    console.log(related_business);
    return (
        <div className="flex flex-col lg:flex-row gap-10 mt-14">
            {related_business.map((item) => {
                let location = '';

                if (item.city) {
                    location += (location ? ', ' : '') + item.city;
                }

                if (item.state) {
                    location += (location ? ', ' : '') + item.state;
                }

                return (
                    <FeaturedBusinessCard
                        title={item.name}
                        rating={parseFloat(item.rating)}
                        stars={'5'}
                        image={item.logo_url}
                        address={item.address}
                        website={item.description}
                        location={location}
                    />
                );
            })}
        </div>
    );
};

export default FeaturedBusiness;
