import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { RiImageAddFill } from "react-icons/ri";

const Upload = () => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div
      className="w-28 h-28 rounded-lg overflow-hidden bg-gray-100 "
      key={file.name}
    >
      <img
        src={file.preview}
        // Revoke data uri after image is loaded
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
        className="w-full h-full object-contain"
        alt=""
      />
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="flex flex-col gap-8">
      <div
        {...getRootProps({ className: "dropzone" })}
        className="border-2 rounded-lg border-dashed border-gray-300 w-28 h-28 flex justify-center items-center"
      >
        <input {...getInputProps()} />
        <RiImageAddFill className="text-2xl text-gray-600" />
      </div>
      {files && files?.length > 0 && (
        <aside className="flex gap-6 flex-wrap">{thumbs}</aside>
      )}
    </section>
  );
};

export default Upload;
