import React from "react";

import {
  IoChatboxEllipses,
  IoThumbsUp,
  IoBriefcase,
  IoAddCircle,
} from "react-icons/io5";

import AboutParagraph from "./AboutParagraph";
import image from "../../images/about.png";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <div className="container mx-auto pt-20 px-6">
      <h1 className="text-3xl font-bold">
        Get the Most Out of <span className="text-green">CityLocal 101</span>
      </h1>
      <div className="flex flex-col-reverse lg:flex-row justify-between items-center py-6 gap-8">
        <div className="flex-1">
          <AboutParagraph
            title="What We Do"
            description="CityLocal 101 is the fastest growing business directory that helps people find the best  businesses in their area. Our platform serves as a bridge between businesses and consumers. With honest and verified reviews, finding the right business has never been"
            color="primary"
            icon={<IoChatboxEllipses className="text-primary text-2xl" />}
          />
          <AboutParagraph
            title="For Reviewers"
            description="Do you love a certain business? Help them grow by leaving a review on their profile. CityLocal 101 users can help businesses and future customers by reviewing their personal experience"
            color="green"
            icon={<IoThumbsUp className="text-green text-2xl" />}
          />
          <AboutParagraph
            title="For Businesses"
            description="We make it easy for business owners to claim their listing and highlight their business information so potential customers can find and contact them faster. To claim your business listing"
            color="secondary"
            icon={<IoBriefcase className="text-secondary text-2xl" />}
          />
          <div className="bg-primary text-white py-3 px-4 rounded-lg flex justify-between items-center gap-4">
            <p style={{ flex: 2 }}>
              Adding a business to CityLocal 101 is always free.
            </p>
            <Link to="/register">
              <button
                className="flex items-center gap-2 border border-white py-2 px-3 rounded-lg"
                style={{ flex: 1 }}
              >
                <IoAddCircle className="text-xl" />
                ADD BUSINESS
              </button>
            </Link>
          </div>
        </div>
        <div className="flex-1">
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
