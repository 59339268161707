export default function LoadingSpinner(props) {
    if (!props.loading) {
        return '';
    }
    return (
        <div className="spinner-container">
            <div className="loading-spinner"></div>
        </div>
    );
}
