import React from "react";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const AccountSettings = () => {
  return (
    <div>
      <div className="flex gap-4">
        <h1 className="text-2xl font-bold">Business Account Information</h1>
        <Link to="/account-settings">
          <FaEdit className="text-2xl text-primary" />
        </Link>
      </div>
      <div className="mt-4">
        <h5>Name: Ahsan Ali</h5>
        <h5>Email: jay@citylocalpro.com</h5>
        <h5>Position: Owner</h5>
      </div>
    </div>
  );
};

export default AccountSettings;
