import React from "react";

const AboutParagraph = ({ title, description, color, icon }) => {
  return (
    <div className="my-6">
      <div className="flex items-center gap-2">
        {icon}
        <h1 className={`text-${color} text-2xl font-semibold`}>{title}</h1>
      </div>
      <p className="mt-3 tracking-wide leading-6">{description}</p>
    </div>
  );
};

export default AboutParagraph;
