export default function Bottom(props) {
    const {widget, query_params} = props;
    if (!widget) {
        return ('');
    }

    const widget_name = widget.widget_name;
    const parallax = widget.parallax ? parseInt(widget.parallax) : 0;
    const widget_width = widget.width ? widget.width : '';
    let widget_class = widget.class ? widget.class : '';

    if (parallax) {
        widget_class += ' parallax';
    }

    const widget_id = widget.id;

    var _style = {};
    if (widget.bg_color) {
        _style.backgroundColor = widget.bg_color;
    }
    if (widget.bg_image) {
        _style.backgroundImage = 'url("' + widget.bg_image + '")';
    }

    if (widget.width === "boxed") {
        return (
            <section key={widget_id} style={_style} className={"container-fluid bottom-widgets " + widget_class } data-id={widget.id}>
                <div className={"container"} dangerouslySetInnerHTML={{__html: widget.html}}/>
            </section>
        )
    } else {
        return (
            <section key={widget_id} style={_style} className={"container-fluid top-widgets " + widget_class} data-id={widget.id} dangerouslySetInnerHTML={{__html: widget.html}}/>
        )
    }

}
