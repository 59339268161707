import React, { useRef, useState } from "react";
import { IoChevronForwardOutline } from "react-icons/io5";

import CoronavirusUpdates from "../components/DashboardComponents/CoronavirusUpdates/CoronavirusUpdates";
import DashboardBanner from "../components/DashboardBanner/DashboardBanner";
import DashboardSidebar from "../components/DashboardSideBar/DashboardSidebar";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import BusinessInformation from "../components/DashboardComponents/BusinessInformation/BusinessInformation";
import CategoriesAndServices from "../components/DashboardComponents/CategoriesAndServices/CategoriesAndServices";
import PhotosAndVideos from "../components/DashboardComponents/PhotosAndVideos/PhotosAndVideos";
import BusinessLocation from "../components/DashboardComponents/BusinessLocation/BusinessLocation";
import DealsAndPromotions from "../components/DashboardComponents/DealsAndPromotions/DealsAndPromotions";
import AccountSettings from "../components/DashboardComponents/AccountSettings/AccountSettings";
import Reviews from "../components/DashboardComponents/Reviews/Reviews";
import Billing from "../components/DashboardComponents/Billing/Billing";

const Dashboard = () => {
  const sideBarRef = useRef();
  const [activeOption, setActiveOption] = useState(1);

  const OPTIONS_SECTIONS = [
    {
      id: 1,
      title: "Coronavirus Updates",
      component: <CoronavirusUpdates />,
    },
    {
      id: 2,
      title: "Business Information",
      component: (
        <BusinessInformation
          onSetOption={(id) => {
            setActiveOption(id);
          }}
        />
      ),
    },
    {
      id: 3,
      title: "Categories & Services",
      component: <CategoriesAndServices />,
    },
    {
      id: 4,
      title: "Photos & Videos",
      component: <PhotosAndVideos />,
    },
    {
      id: 5,
      title: "Business Location",
      component: <BusinessLocation />,
    },
    {
      id: 6,
      title: "Deals & Promotions",
      component: <DealsAndPromotions />,
    },
    {
      id: 7,
      title: "Account Settings",
      component: <AccountSettings />,
    },
    {
      id: 8,
      title: "Reviews",
      component: <Reviews />,
    },
    {
      id: 9,
      title: "Billing",
      component: <Billing />,
    },
  ];

  const scroll = (scrollOffset) => {
    sideBarRef.current.scrollLeft += scrollOffset;
  };

  return (
    <div>
      <Navbar />
      <div className="mt-14">
        <DashboardBanner />
      </div>
      <div className="container mx-auto p-6">
        <div className=" flex flex-col md:flex-row justify-between gap-6 ">
          <div
            className="flex-1 flex flex-col justify-center gap-1 px-4 py-4"
            style={{ backgroundColor: "#F5F7FA" }}
          >
            <h1 className="text-xl font-bold text-primary">
              N.M Trading Company
            </h1>
            <p>New York, NY 10001</p>
          </div>
          <div
            className="py-6 px-8"
            style={{ backgroundColor: "#FFF7CC", flex: 3 }}
          >
            <p className="leading-8">
              Thank you for claiming nm trading on{" "}
              <span className="font-semibold">CityLocal 101</span>. Nm trading
              won’t appear publicly until{" "}
              <span className="font-semibold">CityLocal 101</span> moderators
              approve your business. You will be notified shortly when the
              status changes.{" "}
              <a href="/" className="text-primary font-semibold">
                Learn more
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-6">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex-1 flex gap-3 md:block">
            <div
              className="overflow-x-scroll md:overflow-x-auto"
              ref={sideBarRef}
            >
              <DashboardSidebar
                activeOption={activeOption}
                onChooseOption={(id) => {
                  setActiveOption(id);
                }}
                options={OPTIONS_SECTIONS}
              />
            </div>
            <button className="md:hidden" onClick={() => scroll(150)}>
              <IoChevronForwardOutline className="text-3xl " />
            </button>
          </div>
          <div style={{ flex: 2.89 }}>
            {OPTIONS_SECTIONS[activeOption - 1].component}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
