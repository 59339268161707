import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { RiImageAddFill } from "react-icons/ri";

const DealImageUpload = () => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="flex gap-8">
      <div
        {...getRootProps({ className: "dropzone" })}
        className="border-2 rounded-lg border-dashed border-gray-300 w-28 h-28 flex justify-center items-center"
      >
        <input {...getInputProps()} />
        <RiImageAddFill className="text-2xl text-gray-600" />
      </div>
      {files[0] && (
        <aside className="w-28 h-28 rounded-lg overflow-hidden bg-gray-100 ">
          <img
            src={files[0].preview}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(files[0].preview);
            }}
            className="w-full h-full object-contain"
            alt=""
          />
        </aside>
      )}
    </section>
  );
};

export default DealImageUpload;
