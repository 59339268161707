import React from "react";
import { IoLocation, IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";

const SearchBar = ({ className }) => {
  return (
    <div
      className={`bg-white container mx-auto lg:w-7/12 flex flex-col sm:flex-row sm:justify-between sm:items-center my-6 p-3 sm:p-4 gap-3 sm:gap-6 ${className}`}
      style={{ boxShadow: "0 0 10px rgba(0,0,0, 16%)" }}
    >
      <div className="flex items-center gap-2 flex-1">
        <IoLocation className="text-secondary text-lg" />
        <input
          type="text"
          className=" text-gray-700 focus:outline-none w-full"
          placeholder="Location"
        />
      </div>
      <div className="hidden sm:block border-l border-gray-500 h-14"></div>
      <div style={{ flex: 3 }}>
        <input
          type="text"
          className=" text-gray-700 focus:outline-none w-full"
          placeholder="What are you looking for?"
        />
      </div>
      <div className="flex-1 ">
        <Link to="/search">
          <button className="flex justify-center items-center gap-2 bg-primary text-white font-semibold shadow-sm py-3 px-4 w-full ">
            <IoSearch />
            Search
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SearchBar;
