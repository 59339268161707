import React from "react";

const Button = ({title, type, onClick, className, style}) => {
    return (
        <button
            className={`bg-primary text-white font-semibold shadow-sm py-4 px-8 my-2 ${className}`}
            onClick={onClick}
            style={style}
            type={type ? type : 'button'}
        >
            {title}
        </button>
    );
};

export default Button;
