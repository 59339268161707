import {useEffect, useState} from "react";
import axiosConfig from "./axiosConfig";

const usePost = (url, params) => {
    const [data, setData] = useState(null);
    if (params === undefined) {
        params = {};
    }
    useEffect(() => {
        async function getData() {
            const response = await axiosConfig.post(url + '?cc', params);
            setData(response.data);
        }

        getData();
    }, [url]);

    return [data];
};

export default usePost;
