import React from "react";
import AboutSection from "../components/AboutSection/AboutSection";
import Categories from "../components/Categories/Categories";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Navbar from "../components/Navbar/Navbar";
import PopularListings from "../components/PopularListings/PopularListings";
import Layout from "../components/Layout";

const Home = () => {
  return (
    <Layout page={'home'}>
      {/*<Categories />
      <PopularListings />*/}
      <AboutSection />
    </Layout>
  );
};

export default Home;
