import {createSlice} from '@reduxjs/toolkit'
import {merge} from "lodash/object";

export const listingFilter = createSlice({
    name: 'filters',
    initialState: {
        q: null,
        r: null,
        l: null,
        location: null,
    },
    reducers: {
        setFilter: (state, action) => {
            state = merge(state, action.payload);
        },
        query: (state, action) => {
            state.q = action.payload;
        },
        location: (state, action) => {
            state.location = action.payload;
        },
        state: (state, action) => {
            state.r = action.payload;
        },
        city: (state, action) => {
            state.l = action.payload;
        },
    }
})

// Action creators are generated for each case reducer function
export const {setFilter, query, location, state, city} = listingFilter.actions

export const getFilters = (state) => {
    return state.filters
}

export default listingFilter.reducer
