import React from "react";

import { IoLocation } from "react-icons/io5";

const Banner = ({ firstTitle, secondTitle, listings }) => {
  return (
    <div style={{ backgroundColor: "#F5F7FA" }} className="py-6">
      <div className="container mx-auto flex flex-col xs:flex-row xs:justify-between gap-4 xs:items-center px-6">
        <div className="flex items-center gap-4">
          <h1 className="text-xl font-semibold">{firstTitle}</h1>
          <div className="border-l border-gray-500 h-8"></div>
          <h1 className="text-xl font-semibold text-secondary">
            {secondTitle}
          </h1>
        </div>
        {listings && (
          <div className="flex items-center gap-2">
            <IoLocation className="text-xl text-secondary" />
            <p className="text-secondary">{listings}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
