/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

import React from "react";

window.React = React;

try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {
    console.log(e.toString());
}

window.isLoggedIn = false;
