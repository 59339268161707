import React from "react";
import Widgets from "./Widgets";
import LoadingSpinner from "./LoadingSpinner";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PropTypes from 'prop-types';
import axiosConfig from "../../helpers/axiosConfig";

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            widgets: null
        };
    }

    componentWillMount() {
        const fetchWidgets = async () => {
            await Promise.all([axiosConfig.post("api/web/widgets", {page: (this.props.page?this.props.page:'all')})]).then(resp => {
                this.setState({widgets: resp[0].data})
            });
            /*const response = await axiosConfig.post("api/web/widgets", {page: _page});
            setWidgets(response.data);*/
        };
        fetchWidgets();
    }

    render() {
        let _page = this.props.page;
        let widgets = this.state.widgets;

        const banner_widgets = widgets?.banner ? widgets.banner : null;
        const top_widgets = widgets?.top ? widgets.top : null;
        const bottom_widgets = widgets?.bottom ? widgets.bottom : null;
        const footer_widgets = widgets?.footer ? widgets.footer : null;
        const content_top_widgets = widgets?.content_top ? widgets.content_top : null;
        const content_bottom_widgets = widgets?.content_bottom ? widgets.content_bottom : null;

        return (
            <>
                <PageHeader {...this.props} />
                <LoadingSpinner loading={this.props.loading ? this.props.loading : false}/>
                <Widgets page={_page} position={'banner'} widgets={banner_widgets}/>
                <Widgets page={_page} position={'top'} widgets={top_widgets}/>
                <Widgets page={_page} position={'content-top'} widgets={content_top_widgets}/>
                {this.props.children}
                <Widgets page={_page} position={'content-bottom'} widgets={content_bottom_widgets}/>
                <Widgets page={_page} position={'bottom'} widgets={bottom_widgets}/>
                <PageFooter {...this.props} page={_page} widgets={footer_widgets}/>
            </>
        )
    }
}

Layout.propTypes = {
    page: PropTypes.string,
    children: PropTypes.node,
    loading: PropTypes.bool,
}

export default Layout
