import React from "react";
import { IoStar } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";

import DashboardImage from "./DashboardImage";

const DashboardBanner = () => {
  return (
    <div style={{ backgroundColor: "#F5F7FA" }}>
      <div className="container mx-auto flex flex-col sm:flex-row sm:justify-between sm:items-center py-6 px-6 gap-4">
        <div className="flex flex-col xs:flex-row xs:items-center gap-6">
          <div className="w-28 h-28 rounded-full overflow-hidden bg-white shadow-lg">
            <DashboardImage />
          </div>
          <div>
            <div className="flex items-center gap-4">
              <h1 className="text-xl font-bold">N.M Trading Company</h1>
              <button>
                <FaEdit className="text-2xl text-primary" />
              </button>
            </div>
            <div className="flex items-center gap-3 font-semibold mt-3">
              <div className="flex items-center gap-2">
                <IoStar className="text-yellow text-xl" />
                <IoStar className="text-yellow text-xl" />
                <IoStar className="text-yellow text-xl" />
                <IoStar className="text-yellow text-xl" />
                <IoStar className="text-yellow text-xl" />
              </div>
              <h5>10 Reviews</h5>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 items-start">
          <button className="text-lg font-semibold text-primary">
            View as Consumer
          </button>
          <button className="text-lg font-semibold ">See Reviews</button>
        </div>
      </div>
    </div>
  );
};

export default DashboardBanner;
