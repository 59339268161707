import React from "react";
import logo from "../../images/logo-white.png";
import FooterLinks from "./FooterLinks";

const Footer = () => {
  return (
    <div className="mt-20 bg-primary text-white">
      <div className="py-6 border-b border-white flex justify-center">
        <img src={logo} alt="" />
      </div>
      <div className="container mx-auto px-6">
        <FooterLinks />
      </div>
      <div style={{ backgroundColor: "#0B8ADE" }}>
        <div className="container mx-auto px-6 py-4">
          <p>Copyright © CityLocal 101. All Rights Reserved 2022</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
