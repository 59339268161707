import React from "react";
import { useNavigate } from "react-router-dom";
import { IoEarth, IoCall, IoMail, IoStar, IoLocation } from "react-icons/io5";
import image from "../../images/featured.png";

const FeaturedBusinessListingCard = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col md:flex-row justify-between bg-primary text-white my-8 cursor-pointer "
      style={{ boxShadow: "0 0 10px rgba(0,0,0,16%)" }}
      onClick={() => {
        navigate("./1");
      }}
    >
      <div className="flex-1 h-auto w-auto">
        <img
          src={image}
          alt=""
          className="h-full w-full max-h-72 md:max-h-max object-cover"
        />
      </div>
      <div className="p-6" style={{ flex: 2 }}>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-10">
          <div>
            <h1 className="text-xl font-bold">The Red Flame Dinner</h1>
            <p className="my-1">Restaurants, Pizza, Italian Grocery Stores</p>
            <div className="flex items-center gap-3 font-semibold">
              <h5>Rating</h5>
              <h5>5.0</h5>
              <div className="flex items-center gap-2">
                <IoStar className="text-yellow text-xl" />
                <IoStar className="text-yellow text-xl" />
                <IoStar className="text-yellow text-xl" />
                <IoStar className="text-yellow text-xl" />
                <IoStar className="text-yellow text-xl" />
              </div>
            </div>
          </div>
          <div className="py-2 flex flex-col gap-1">
            <a
              href="https://www.website.com"
              rel="noreferrer"
              target="_blank"
              className="flex items-center gap-2"
            >
              <IoEarth />
              www.website.com
            </a>
            <a
              href="https://www.website.com"
              rel="noreferrer"
              target="_blank"
              className="flex items-center gap-2"
            >
              <IoCall />
              (347) 329 5463
            </a>
            <a
              href="https://www.website.com"
              rel="noreferrer"
              target="_blank"
              className="flex items-center gap-2"
            >
              <IoMail />
              info@email.com
            </a>
          </div>
        </div>
        <p className="my-1 text-sm leading-6">
          It is a long established fact that a reader will be distracted by
          content of a page when looking at its layout. The point of using.
        </p>
        <div className="flex gap-4 items-center my-2">
          <button>Website</button>
          <div className="border-l border-white h-6"></div>
          <button>View Menu</button>
        </div>
        <p>
          <span className="font-bold">20</span> Years in Business
        </p>
        <div className="flex flex-col xl:flex-row xl:justify-between xl:items-center gap-3 mt-2 xl:mt-1">
          <div className="flex items-center gap-2">
            <IoLocation className="text-lg" />
            <p>800 N Lamar Blvd, Austin USA</p>
          </div>
          <div className="flex gap-4 flex-wrap">
            <button className="bg-secondary text-white font-semibold shadow-sm py-2 px-4 xl:mx-2">
              Get Directions
            </button>
            <button className="bg-secondary text-white font-semibold shadow-sm py-2 px-4">
              Open 24/7
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedBusinessListingCard;
