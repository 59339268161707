import {React, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Button from "../components/Shared/Button/Button";
import Alert from "../components/Shared/Alert/Alert";
import TextField from "../components/Shared/TextField/TextField";
import Layout from "../components/Layout";
import axiosConfig from "../helpers/axiosConfig";
import {getErrors} from "../helpers/core";

const Login = () => {
    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const handleSubmit = async event => {
        event.preventDefault();
        setError(null);
        var json = {"login": email, "password": password};
        axiosConfig.post("/api/web/login", json).then((response) => {
            console.log(response);
            if (response.data.success) {
                setMessage(response.data.message);
                window.isLoggedIn = true;
                navigate("/");
                //navigate("/account");
            } else {
                setError(response.data.message);
            }
        }).catch(err => {
            setError(getErrors(err));
        });
    }

    return (
        <Layout page={'login'}>
            <div
                className="container mx-auto flex justify-center items-center"
                style={{minHeight: "85vh"}}>
                <div className="max-w-2xl p-6 sm:p-10 text-center sm:text-left ">
                    <form onSubmit={handleSubmit}>
                        <h1 className="text-4xl font-bold tracking-wide">Welcome Back</h1>
                        <p className="text-lg leading-8 my-5 tracking-wide">
                            By continuing, you agree to CityLocal101 Terms of Service and
                            acknowledge our Privacy Policy.
                        </p>
                        <div className="my-6 flex flex-col gap-6">
                            <TextField label="Add Your Email" id="email" required type="email" onChange={event => setEmail(event.target.value)}/>
                            <TextField label="Password" id="password" required type="password" onChange={event => setPassword(event.target.value)}/>
                        </div>

                        {error && <div className="grid-cols-12"><Alert variant={'danger'} icon={true} dismissable={true} text={error}/></div>}
                        {message && <div className="grid-cols-12"><Alert variant={'success'} icon={true} dismissable={true} text={message}/></div>}

                        <div className="text-right">
                            <Link to="/forgot-password">Forget Password?</Link>
                        </div>

                        <div className="my-6">
                            <Button title="Login" type={'submit'} className="w-full"/>
                        </div>
                        <p className="text-center my-4">
                            Don't have an account? Claim your business on{" "}
                            <span className="text-primary"> <Link to="/register">CityLocal101</Link> </span>
                        </p>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default Login;
