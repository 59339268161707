export default function Footer(props) {
    const {widget, query_params} = props;
    if (!widget) {
        return ('');
    }

    const widget_name = widget.widget_name;
    const widget_title = widget.title ? widget.title : 'none';
    const parallax = widget.parallax ? parseInt(widget.parallax) : 0;
    const widget_width = widget.width ? widget.width : '';
    let widget_class = widget.class ? widget.class : '';

    if (parallax) {
        widget_class += ' parallax';
    }

    const widget_id = widget.id;

    var _cols = 12;


    var _show_title = (widget_title && !widget.hide_title);

    if (widget.block_width && widget.block_width <= 12) {
        _cols = widget.block_width;
    }

    var _style = {};
    if (widget.bg_color) {
        _style.backgroundColor = widget.bg_color;
    }
    if (widget.bg_image) {
        _style.backgroundImage = 'url("' + widget.bg_image + '")';
    }

    return (
        <div key={widget_id} md={_cols} style={_style} className={"widget-column"} data-id={widget.id}>
            <div className={widget.class}>
                {_show_title ? <h4>{widget_title}</h4> : ''}
                <div className="widget-body" dangerouslySetInnerHTML={{__html: widget.html}}/>
            </div>
        </div>
    )
}
