import React, { useState } from "react";
import DashboardBanner from "../components/DashboardBanner/DashboardBanner";
import DashboardSidebar from "../components/DashboardSideBar/DashboardSidebar";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import ChangeAccountInfo from "../components/AccountSettingsComponents/ChangeAccountInfo/ChangeAccountInfo";
import ChangePassword from "../components/AccountSettingsComponents/ChangePassword/ChangePassword";

const OPTIONS_SECTIONS = [
  {
    id: 1,
    title: "Account Information",
    component: <ChangeAccountInfo />,
  },
  {
    id: 2,
    title: "Password",
    component: <ChangePassword />,
  },
];

const AccountSettings = () => {
  const [activeOption, setActiveOption] = useState(1);

  return (
    <div>
      <Navbar />
      <div className="mt-14">
        <DashboardBanner />
      </div>
      <div className="container mx-auto p-6">
        <div className=" flex flex-col md:flex-row justify-between gap-6 ">
          <div
            className="flex-1 flex flex-col justify-center items-center gap-1 px-4 py-4"
            style={{ backgroundColor: "#F5F7FA" }}
          >
            <h1 className="text-xl font-bold">Account Settings</h1>
          </div>
          <div
            className="py-6 px-8"
            style={{ backgroundColor: "#FFF7CC", flex: 3 }}
          >
            <p className="leading-8">
              Thank you for claiming nm trading on{" "}
              <span className="font-semibold">CityLocal 101</span>. Nm trading
              won’t appear publicly until{" "}
              <span className="font-semibold">CityLocal 101</span> moderators
              approve your business. You will be notified shortly when the
              status changes.{" "}
              <a href="/" className="text-primary font-semibold">
                Learn more
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-6">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex-1 overflow-x-scroll md:overflow-x-auto">
            <DashboardSidebar
              activeOption={activeOption}
              onChooseOption={(id) => {
                setActiveOption(id);
              }}
              options={OPTIONS_SECTIONS}
            />
          </div>
          <div style={{ flex: 2.89 }}>
            {OPTIONS_SECTIONS[activeOption - 1].component}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccountSettings;
