import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const BusinessGallerySlider = ({company}) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: false,
    responsive: [
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="my-10 px-3">
      <Slider {...settings}>
        {company.gallery && company.gallery.map((image, key) => {
          return (<img src={image.url} alt="" className=" object-cover px-2 xs:px-4" key={image.id} />)
        })}
      </Slider>
    </div>
  );
};

export default BusinessGallerySlider;
