import React from "react";
import { IoArrowForward } from "react-icons/io5";

import image from "../../../images/reviews.png";
import IndivReviewCard from "./IndivReviewCard";

const Reviews = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Reviews</h1>
      <div>
        <div className="mt-6">
          <img src={image} alt="" />
        </div>
        <div className="mt-4">
          <h1 className="text-xl font-semibold text-primary">
            No reviews yet?
          </h1>
          <p className="mt-2 leading-8">
            Sometimes it can take a little while for your first review.
          </p>
        </div>
        <div className="mt-4">
          <h1 className="text-xl font-semibold">
            What you can do now to help you get high quality reviews
          </h1>
          <p className="mt-2 leading-8">
            Don't worry, sometimes it can take a little while to get your first
            review. Complete your profile and share your link with your
            customers.
          </p>
          <button className="flex items-center gap-1 text-primary mt-4">
            Set up your page
            <IoArrowForward className="text-xl" />
          </button>
        </div>
      </div>
      <div className="mt-6">
        <IndivReviewCard
          name="Andrew Hudson"
          date="March 11, 2021, 6:32 am"
          rating="5.0"
          stars={5}
          review="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letter."
        />
        <IndivReviewCard
          name="Andrew Hudson"
          date="March 11, 2021, 6:32 am"
          rating="5.0"
          stars={5}
          review="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letter."
        />
      </div>
    </div>
  );
};

export default Reviews;
