import React, {useState} from "react";
import {IoClose, IoMenu, IoPersonCircle} from "react-icons/io5";
import {MdLiveHelp} from "react-icons/md";
import {FaEdit} from "react-icons/fa";
import {RiShareBoxFill} from "react-icons/ri";

import logo from "../../images/logo.png";
import {Link} from "react-router-dom";

const PageHeader = () => {
    const [showMobileNav, setShowMobileNav] = useState(false);

    const mobileNavHandler = () => {
        if (!showMobileNav) {
            document.querySelector("body").style.overflowY = "hidden";
        } else {
            document.querySelector("body").style.overflowY = "auto";
        }
        setShowMobileNav((prevValue) => !prevValue);
    };
    return (
        <nav className="container-sm mx-auto py-6 px-6 xl:px-24 flex justify-between items-center gap-4">
            <div>
                <Link to="/">
                    <img src={logo} alt="City Local"/>
                </Link>
            </div>
            <button
                className="text-3xl lg:hidden text-black "
                onClick={mobileNavHandler}
            >
                <IoMenu/>
            </button>
            {showMobileNav && (
                <div
                    className="fixed top-0 left-0 bg-black w-full h-full opacity-40 z-30 overflow-hidden"
                    onClick={mobileNavHandler}
                ></div>
            )}
            {showMobileNav && (
                <div className="bg-primary text-white fixed top-0 right-0 h-screen z-50 flex flex-col gap-10 py-6 px-8 overflow-hidden">
                    <button
                        className="flex justify-end text-3xl lg:hidden"
                        onClick={mobileNavHandler}
                    >
                        <IoClose/>
                    </button>
                    <a href="/" className=" font-semibold flex items-center gap-2">
            <span>
              <MdLiveHelp className=" text-2xl "/>
            </span>{" "}
                        Support
                    </a>
                    <a href="/" className="font-semibold flex items-center gap-2">
            <span>
              <FaEdit className="text-2xl"/>
            </span>{" "}
                        Write a Review
                    </a>
                    <a href="/" className="font-semibold flex  items-center gap-2 ">
                        For Businesses
                        <span>
              <RiShareBoxFill className="text-2xl"/>
            </span>{" "}
                    </a>
                    <Link to="/login" className="flex">
                        <button className="flex items-center gap-2 font-semibold">
                            <IoPersonCircle className="text-2xl"/>
                            LOGIN
                        </button>
                    </Link>
                    <Link to="/register">
                        <button className="py-3 px-4 rounded-md bg-white text-gray-700">
                            Add Your Business
                        </button>
                    </Link>
                </div>
            )}
            <div className="hidden lg:flex gap-6 flex-1 xl:px-10">
                <a
                    href="/"
                    className=" font-semibold flex justify-center items-center gap-2"
                >
          <span>
            <MdLiveHelp className="text-secondary text-2xl "/>
          </span>{" "}
                    Support
                </a>
                <a
                    href="/"
                    className="font-semibold flex justify-center items-center gap-2"
                >
          <span>
            <FaEdit className="text-secondary text-2xl"/>
          </span>{" "}
                    Write a Review
                </a>
                <a
                    href="/"
                    className="font-semibold flex justify-center items-center gap-2 text-secondary "
                >
                    For Businesses
                    <span>
            <RiShareBoxFill className="text-2xl"/>
          </span>{" "}
                </a>
            </div>
            <div className="hidden lg:flex gap-8 ">
                <Link to="/login" className="flex">
                    <button className="flex items-center gap-2 font-semibold">
                        <IoPersonCircle className="text-secondary text-2xl"/>
                        LOGIN
                    </button>
                </Link>
                <Link to="/register">
                    <button className="bg-secondary py-3 px-4 rounded-md text-white">
                        Add Your Business
                    </button>
                </Link>
            </div>
        </nav>
    );
};

export default PageHeader;
