import React from "react";
import SideBarOptions from "./SideBarOptions";

const DashboardSidebar = ({ activeOption, onChooseOption, options }) => {
  return (
    <div className="flex w-max md:w-auto md:flex-col gap-2 md:gap-1">
      {options.map((option) => (
        <SideBarOptions
          key={option.id}
          title={option.title}
          active={option.id === activeOption}
          onClick={() => {
            onChooseOption(option.id);
          }}
        />
      ))}
    </div>
  );
};

export default DashboardSidebar;
