import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import queryString from 'query-string';
import axiosConfig from "./axiosConfig";

export const useQuery = () => {
    const location = useLocation();
    const params = queryString.parse(location.search)
    return params ? params : {};
}

export const toPascalCase = (string) => {
    return `${string}`
        .toLowerCase()
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/\s+(.)(\w*)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3}`
        )
        .replace(new RegExp(/\w/), s => s.toUpperCase());
}

export const useMutationAwait = (url, options) => {
    const [body, setBody] = useState({});
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleData = (callback) => {
        if (data) {
            callback(data);
            setData(null);
        }
    }

    const handleError = (callback) => {
        if (error) {
            callback(error);
            setError(null);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            setIsLoading(true);
            try {
                await axiosConfig
                    .post(url, body, options)
                    .then((response) => setData(response.data))
                    .catch((error) => setError(getErrors(error)))
                    .finally(() => setIsLoading(false));
            } catch (error) {
                console.error(error.response.data);
                setError(getErrors(error));
            }
            setIsLoading(false);
        }
        fetchData();
    }, [body]);

    return [{isLoading, data, handleData, error, handleError}, setBody];
};

export const getErrors = (response) => {
    let _errors;

    if (response.response) {
        response = response.response;
    }
    if (response.data.errors) {
        _errors = '';
        Object.keys(response.data.errors).map((k, i) => {
            _errors = (_errors ? _errors + "<br/>" : '') + response.data.errors[k][0];
        })
    } else if (response.data.message) {
        _errors = response.data.message;
    }
    return _errors;
}

export const data_get = (target, path, fallback) => {
    if (!target) {
        return target;
    }
    if (fallback === undefined) {
        fallback = null;
    }
    let segments = Array.isArray(path) ? path : path.split('.');
    let [segment] = segments;

    let find = target;

    if (segment !== '*' && segments.length > 0) {
        if (find[segment] === null || typeof find[segment] === 'undefined') {
            find = typeof fallback === 'function' ? fallback() : fallback;
        } else {
            find = data_get(find[segment], segments.slice(1), fallback);
        }
    } else if (segment === '*') {
        const partial = segments.slice(path.indexOf('*') + 1, path.length);


        if (typeof find === 'object') {
            find = Object.keys(find).reduce((build, property) => ({
                    ...build,
                    [property]: data_get(find[property], partial, fallback)
                }),
                {});
        } else {
            find = data_get(find, partial, fallback);
        }
    }
    if (find !== null && typeof find === 'object') {
        if (Object.keys(find).length > 0) {
            const isArrayTransformable = Object.keys(find).every(index => index.match(/^(0|[1-9][0-9]*)$/));

            return isArrayTransformable ? Object.values(find) : find;
        }
    } else {
        return find;
    }
};

export const data_set = function data_set(target, path, value, force = true) {
    let segments = Array.isArray(path) ? path : path.split('.');
    let [segment] = segments;

    if (segments.length === 0) {
        target = value;
    } else if (segments.length === 1 && !segments.includes('*')) {
        target[segment] = force ? value : target[segment] || value;
    } else if (segment !== '*') {
        if (!target[segment]) {
            target[segment] = {};

            target = data_set(target[segment], segments.slice(1), value, force);
        }

        let inner = data_set(target[segment], segments.slice(1), value, force);

        if (Array.isArray(target[segment])) {
            if (force && target[segment].length) {
                target[segment] = [...target[segment]];
            } else {
                target[segment] = [...inner];
            }
        } else {
            target[segment] = force ? {...target[segment], ...inner} : {...inner, ...target[segment]};
        }
    } else if (segment === '*') {
        const partial = segments.slice(path.indexOf('*') + 1, path.length);

        if (typeof target === 'object') {
            target = Object.keys(target).reduce((build, property) => ({
                    ...build,
                    [property]: data_set(target[property], partial, value, force)
                }),
                {});
        } else {
            target = data_set(target, partial, value, force);
        }
    }

    const arrayable = [
        typeof target === 'object',
        Object.keys(target).length,
        Object.keys(target).every(index => index.match(/^(0|[1-9][0-9]*)$/))
    ];

    if (arrayable.every(requirement => requirement === true)) {
        return Object.values(target);
    }

    return target;
};


