import {createSlice} from '@reduxjs/toolkit'
import {merge} from "lodash/object";

export const alertsState = createSlice({
    name: 'alerts',
    initialState: {
        errors: null,
        messages: null,
        warnings: null,
    },
    reducers: {
        setAlerts: (state, action) => {
            state = merge(state, action.payload);
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setMessages: (state, action) => {
            state.messages = action.payload;
        },
        setWarnings: (state, action) => {
            state.warnings = action.payload;
        },
    }
})

// Action creators are generated for each case reducer function
export const {setAlerts, setErrors, setMessages, setWarnings} = alertsState.actions

export const getErrors = (state) => {
    return state.alerts.errors
}

export const getMessages = (state) => {
    return state.alerts.message
}

export const getWarnings = (state) => {
    return state.alerts.warnings
}

export default alertsState.reducer
