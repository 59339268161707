import React from "react";
import {
  FaCcMastercard,
  FaCcVisa,
  FaCcAmazonPay,
  FaCcAmex,
  FaCcApplePay,
  FaCcPaypal,
  FaCcStripe,
} from "react-icons/fa";
import Button from "../../Shared/Button/Button";
import TextField from "../../Shared/TextField/TextField";

const Billing = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Current Billing Information</h1>
      <p className="mt-4 leading-8">
        Your <span className="text-secondary">American Express*1001</span> is
        currently added in our system.
      </p>
      <button className="mt-4 underline">
        Update Your Billing information
      </button>
      <div className="mt-6">
        <h1 className="text-xl font-bold">Add your Payment Method</h1>
        <div className="flex gap-6 text-3xl mt-4 flex-wrap">
          <FaCcMastercard />
          <FaCcVisa />
          <FaCcAmazonPay />
          <FaCcAmex />
          <FaCcApplePay />
          <FaCcPaypal />
          <FaCcStripe />
        </div>
      </div>
      <form className="mt-6 flex flex-col gap-4">
        <TextField label="Name on Card" id="name" type="text" />
        <TextField label="Card Number" id="card_number" type="number" />
        <TextField label="MM/YY" id="date" type="date" />
        <TextField label="CVV" id="cvv" type="number" />
      </form>
      <div className="flex gap-3 mt-4">
        <Button
          title="Cancel"
          style={{ backgroundColor: "#F5F7FA" }}
          className="text-gray-700"
        />
        <Button title="Save" />
      </div>
    </div>
  );
};

export default Billing;
