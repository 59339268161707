import React from "react";

const CovidUpdatesContainer = () => {
  return (
    <div className="border border-gray-400 p-3 xs:p-6 my-6">
      <h1 className="text-2xl font-semibold">Covid-19 Updates</h1>
      <ul className="mt-3">
        <li className="my-2 text-lg">Mask required</li>
        <li className="my-2 text-lg">All staff fully vaccinated</li>
        <li className="my-2 text-lg">Staff wears mask</li>
        <li className="my-2 text-lg">Proof of vaccination required</li>
      </ul>
    </div>
  );
};

export default CovidUpdatesContainer;
