import React, { useState } from "react";
import Modal from "react-modal";

import { IoClose } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { IoArrowForward } from "react-icons/io5";
import Button from "../../Shared/Button/Button";
import BusinessHours from "./BusinessHours";

Modal.setAppElement("#root");

const BusinessInformation = ({ onSetOption }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex gap-4">
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
          content: {
            padding: "0",
            maxWidth: "600px",
            margin: "auto",
            overflow: "hidden",
          },
        }}
        contentLabel="Set Your Business Hours"
      >
        <div className="flex justify-between items-center p-4 border-b border-gray-400">
          <h1 className="text-2xl font-bold">Set Your Business Hours</h1>
          <button
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <IoClose className="text-2xl " />
          </button>
        </div>
        <div>
          <BusinessHours />
        </div>
        <div className="flex mt-4 gap-4 justify-end px-6 ">
          <Button
            title="Cancel"
            style={{ backgroundColor: "#EA4235" }}
            onClick={() => setIsModalOpen(false)}
          />
          <Button title="Save Schedule" />
        </div>
      </Modal>
      <div className="flex-1">
        <div className="border-b border-gray-300 pb-8">
          <div className="flex items-center gap-4">
            <h1 className="text-xl font-bold">Updates during Coronavirus</h1>
            <button
              onClick={() => {
                onSetOption(1);
              }}
            >
              <FaEdit className="text-primary text-2xl" />
            </button>
          </div>
          <p className="mt-4 leading-8">
            Give your customers up-to-date information about your hours,
            services, and any new updates about your business.
          </p>
          <button
            className="flex items-center gap-1 text-primary mt-4"
            onClick={() => {
              onSetOption(1);
            }}
          >
            Go to COVID-19 updates
            <IoArrowForward className="text-xl" />
          </button>
        </div>
        <div className="border-b border-gray-300 py-8">
          <div className="flex items-center gap-4">
            <h1 className="text-xl font-bold">Categories and Services</h1>
            <button
              onClick={() => {
                onSetOption(3);
              }}
            >
              <FaEdit className="text-primary text-2xl" />
            </button>
          </div>
          <p className="mt-4 leading-8">
            Customers search for businesses to connect with based on categories
            and services.
          </p>
          <button
            className="flex items-center gap-1 text-primary mt-4"
            onClick={() => {
              onSetOption(3);
            }}
          >
            Add services
            <IoArrowForward className="text-xl" />
          </button>
        </div>
        <div className="border-b border-gray-300 py-8">
          <div className="flex items-center gap-4">
            <h1 className="text-xl font-bold">Photos and Videos</h1>
            <button
              onClick={() => {
                onSetOption(4);
              }}
            >
              <FaEdit className="text-primary text-2xl" />
            </button>
          </div>
          <p className="mt-4 leading-8">
            Share photos and videos of your business, products services, and
            team.
          </p>
          <button
            className="flex items-center gap-1 text-primary mt-4"
            onClick={() => {
              onSetOption(4);
            }}
          >
            Add photos
            <IoArrowForward className="text-xl" />
          </button>
        </div>
        <div className="border-b border-gray-300 py-8">
          <div className="flex items-center gap-4">
            <h1 className="text-xl font-bold">Hours of Operation</h1>
            <button
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <FaEdit className="text-primary text-2xl" />
            </button>
          </div>
          <p className="mt-4 leading-8">
            What are your holiday hours? Let your customers know about special
            hours and closures.
          </p>
          <button
            className="flex items-center gap-1 text-primary mt-4"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Monday To Friday 9:00AM TO 6:00 PM
            <IoArrowForward className="text-xl" />
          </button>
        </div>
        <div className="border-b border-gray-300 py-8">
          <div className="flex items-center gap-4">
            <h1 className="text-xl font-bold">Business Description</h1>
            <button>
              <FaEdit className="text-primary text-2xl" />
            </button>
          </div>
          <p className="mt-4 leading-8">Tell us more about your business.</p>
          <button className="flex items-center gap-1 text-primary mt-4">
            Add description
            <IoArrowForward className="text-xl" />
          </button>
        </div>
        <div className="border-b border-gray-300 py-8">
          <div className="flex items-center gap-4">
            <h1 className="text-xl font-bold">Social Media Links</h1>
            <button>
              <FaEdit className="text-primary text-2xl" />
            </button>
          </div>
          <p className="mt-4 leading-8">
            Add links to your social media profiles so your customers can stay
            connected.
          </p>
          <button className="flex items-center gap-1 text-primary mt-4">
            Add links
            <IoArrowForward className="text-xl" />
          </button>
        </div>
      </div>
      <div className="hidden lg:block">
        <div style={{ backgroundColor: "#F5F7FA" }} className="py-4 px-6">
          <p>Profile Status</p>
          <h1 className="text-xl font-bold mt-2">Complete your profile</h1>
          <p className="mt-3 text-primary ">
            Help customers find your business
          </p>
          <Button title="Complete profile" className="mt-4" />
        </div>
        <div className="mt-6">
          <h1 className="text-xl font-bold">Business info</h1>
          <div className="mt-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d101408.03263151072!2d-122.15130718904442!3d37.41345079176281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb7495bec0189%3A0x7c17d44a466baf9b!2sMountain%20View%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1658553180755!5m2!1sen!2s"
              height="300"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full"
              title="location"
            ></iframe>
          </div>
          <div className="mt-4">
            <div className="mt-3">
              <h1 className="text-lg font-semibold mt-2">Address</h1>
              <button className="mt-1 text-primary ">
                Add complete address
              </button>
            </div>
            <div className="mt-3">
              <h1 className="text-lg font-semibold mt-2">Phone Number</h1>
              <button className="mt-1 text-primary ">(515) 523-5203</button>
            </div>
            <div className="mt-3">
              <h1 className="text-lg font-semibold mt-2">Website</h1>
              <button className="mt-1 text-primary ">
                Add a link to your business website
              </button>
            </div>
            <div className="mt-3">
              <h1 className="text-lg font-semibold mt-2">Service area</h1>
              <button className="mt-1 text-primary ">
                Define your services areas
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;
