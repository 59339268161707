import Top from "./WidgetGroups/Top";
import Bottom from "./WidgetGroups/Bottom";
import Banner from "./WidgetGroups/Banner";
import Footer from "./WidgetGroups/Footer";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

function useQuery() {
    const location = useLocation();
    const params = queryString.parse(location.search)
    return params ? params : {};
}

export default function Widgets(props) {
    const {page, position, widgets} = props;
    const params = useQuery();
    return (
        widgets && widgets.map((widget, key) => {
                const _position = widget.position;
                if (_position === 'top') {
                    return (<Top key={key} widget={widget} query_params={params}/>)
                } else if (_position === 'bottom') {
                    return (<Bottom key={key} widget={widget}/>)
                } else if (_position === 'banner') {
                    return (<Banner key={key} widget={widget}/>)
                } else if (_position === 'footer') {
                    return (
                        <footer className="footer">
                            <div className="footer-links-wrapper">
                                <div className="container">
                                        <Footer widget={widget}/>
                                </div>
                            </div>
                        </footer>
                    )
                } else {
                    return ('')
                }
            }
        )
    )
}
