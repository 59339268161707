import React from "react";
import RestaurantListingCard from "./RestaurantListingCard";

const RestaurantsListings = () => {
  return (
    <div className="container mx-auto py-6 px-6">
      <h1 className="text-3xl font-bold">Restaurants Listings</h1>
      <RestaurantListingCard />
      <RestaurantListingCard />
      <RestaurantListingCard />
      <RestaurantListingCard />
      <RestaurantListingCard />
      <RestaurantListingCard />
      <RestaurantListingCard />
    </div>
  );
};

export default RestaurantsListings;
