import {configureStore} from '@reduxjs/toolkit'

import rootReducer from "./reducers";
import {combineReducers} from "redux";
import filtersReducer from "./states/listingFilter";
import alertsReducer from "./states/alertsState";

export default configureStore({
    reducer: combineReducers({
        filters: filtersReducer,
        alerts: alertsReducer
    }, rootReducer),
    //preloadedState: {}
})

/*export default configureStore({
    reducer: {
        filters: filtersReducer
    }
})*/
