import React from "react";
import SearchBar from "../components/SearchBar/SearchBar";
import Banner from "../components/Banner/Banner";
import BusinessInfo from "../components/BusinessInfo/BusinessInfo";
import BusinessGallery from "../components/BusinessGallery/BusinessGallery";
import DealsCard from "../components/DealsCard/DealsCard";

import dealImage from "../images/deal.png";
import ReviewCard from "../components/ReviewCard/ReviewCard";
import MapContainer from "../components/MapContainer/MapContainer";
import CovidUpdatesContainer from "../components/CovidUpdatesContainer/CovidUpdatesContainer";
import BusinessHoursContainer from "../components/BusinessHoursContainer/BusinessHoursContainer";
import SocialMediaContainer from "../components/SocialMediaContainer/SocialMediaContainer";
import ReviewForm from "../components/ReviewForm/ReviewForm";
import FeaturedBusiness from "../components/FeaturedBusiness/FeaturedBusiness";
import {useParams} from "react-router-dom";
import usePost from "../helpers/post";
import Layout from "../components/Layout";

const IndivBusiness = () => {
    const params = useParams();
    const [data] = usePost("/api/web/company/details", {slug: params.slug});

    const company = (data?.company ? data.company : null);
    const mata_data = (data?.mata_data ? data.mata_data : null);
    const related_business = (data?.related_business ? data.related_business : null);

    let reviews = company?.reviews ? company?.reviews : null

    return (
        <Layout page={'company'}>
            {company && <>
                <div className="px-6">
                    <SearchBar className="mt-20"/>
                </div>
                <Banner firstTitle="Home" secondTitle={company.name}/>
                <div className="container mx-auto p-6">
                    <BusinessInfo company={company}/>
                    <BusinessGallery company={company}/>
                    <div className="flex flex-col md:flex-row justify-between gap-8 mt-6">
                        <div>
                            <div className="mt-3">
                                <h1 className="text-3xl font-bold ">Business Description</h1>
                                <p className="my-3 leading-8" dangerouslySetInnerHTML={{__html: company.description}}/>
                            </div>
                            <div className="mt-8">
                                <h1 className="text-2xl font-bold ">Deals & Promotions</h1>
                                <DealsCard
                                    title="Deal of the Day"
                                    description="It is a long established fact that a reader will be distracted by the readable of a page when looking at its layout."
                                    image={dealImage}
                                />
                            </div>
                            <div className="mt-8">
                                <h1 className="text-2xl font-bold ">Reviews</h1>
                                {(reviews && reviews.length > 0) ? reviews.map((review, key) => {
                                    return (<ReviewCard review={review}/>)
                                }) : <div><h2>No Reviews yet!</h2></div>}
                            </div>
                        </div>
                        <div>
                            <MapContainer company={company}/>
                            <CovidUpdatesContainer company={company}/>
                            <BusinessHoursContainer company={company}/>
                            <SocialMediaContainer company={company}/>
                        </div>
                    </div>
                    <div>
                        <ReviewForm company={company}/>
                    </div>
                    <FeaturedBusiness related_business={related_business}/>
                </div>
            </>}
        </Layout>
    );
};

export default IndivBusiness;
