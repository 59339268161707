import {useNavigate} from 'react-router-dom';
import {data_get} from "../../helpers/core";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getFilters, setFilter} from "../../redux/states/listingFilter";

import * as jQuery from "jquery";

require('devbridge-autocomplete');

export default function SearchForm(props) {
    const navigate = useNavigate();

    const _filters = useSelector(getFilters);
    const dispatch = useDispatch()

    const {widget, position, query_params} = props;
    let title = widget.title ? widget.title : (widget.name ? widget.name : '');
    let sub_title = data_get(widget, 'sub_title', '');
    let options = data_get(widget, 'options');

    let hide_titles = parseInt(data_get(options, 'hide_titles'));
    let show_as_banner = parseInt(data_get(options, 'show_as_banner'));
    let hide_where = parseInt(data_get(options, 'hide_where', 0));
    let hide_categories = parseInt(data_get(options, 'hide_categories', 0));
    let hide_what = parseInt(data_get(options, 'hide_what', 0));

    let placeholder_what = data_get(options, 'placeholder_what', "What?");
    let placeholder_where = data_get(options, 'placeholder_where', "Where?");
    let text_below_search = data_get(options, 'text_below_search', '');
    let button_text = data_get(options, 'button_text', '');

    let qString = data_get(_filters, 'q', (query_params?.q ? query_params?.q : ''));
    let qLocation = data_get(_filters, 'location', (query_params?.r ? '' + query_params?.r : (query_params?.location ? query_params?.location : '')));
    let qLocationId = data_get(_filters, 'l', (query_params?.l ? query_params?.l : ''));
    let qAdmin = data_get(_filters, 'r', (query_params?.r ? query_params?.r : ''));

    let _input = {
        q: qString,
        location: qLocation,
        r: qAdmin,
        l: qLocationId
    }

    function _handleWhat(event) {
        _input.q = event.target.value;
    }

    function onFormSubmit(event) {
        event.preventDefault();

        _input.location = jQuery('#locSearch').val();

        let url = '/search';

        dispatch(setFilter(_input));

        if (window.location.pathname != url) {
            if (_input) {
                const queryString = new URLSearchParams(_input).toString();
                url = url + '?' + queryString;
            }
            navigate(url);
        }
    }

    function _devbridgeAutocomplete() {

        jQuery('input#locSearch').devbridgeAutocomplete({
            zIndex: 1492,
            serviceUrl: window.site_url + '/ajax/countries/' + ('US') + '/autocomplete',
            type: 'post',
            data: {
                'city': jQuery(this).val(),
                '_token': jQuery('input[name=_token]').val()
            },
            minChars: 1,
            formatResult: function (suggestion, currentValue) {
                var pattern = '(' + currentValue.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&") + ')';
                return suggestion.value.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/&lt;(\/?strong)&gt;/g, '<$1>') + (suggestion.sub ? ', ' + suggestion.sub : '');
            },
            onSelect: function (suggestion) {
                var val = jQuery(this).val();
                var city = suggestion.data.city_id;
                var state = suggestion.data.sb1;
                jQuery(this).attr('data-selected-city', city)
                    .attr('data-selected-state', state)
                    .attr('data-location', val);
                _input.location = val;
                _input.l = city;
                _input.r = state;
            }
        }).on('keyup', function (e) {
            var elm = jQuery(this),
                l = elm.attr('data-selected-city'),
                r = elm.attr('data-selected-state'),
                lc = elm.attr('data-location');
            if (elm.val() != lc) {
                _input.location = elm.val();
                _input.l = '';
                _input.r = '';
            } else {
                _input.l = l;
                _input.r = r;
            }
        });
    }

    useEffect(() => {
        _devbridgeAutocomplete();
    }, []);

    return (
        <div className={"hero-area text-center " + (show_as_banner ? "banner-search" : "compact-search py-3")} data-overlay="dark" data-opacity="6">
            {show_as_banner == 1 && hide_titles != 1 && title &&
                <div className="banner-content">
                    <div>
                        <div className="container">
                            <h1 className="title">{title}</h1>
                            {sub_title &&
                                <p>{sub_title}</p>
                            }
                        </div>
                    </div>
                </div>
            }
            <div className="advance-search mb-0">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12 align-content-center">
                        <form className="mb-0" onSubmit={onFormSubmit}>
                            <div className="form-row">
                                {!hide_where &&
                                    <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                                        <input type="text" key={'locSearch'} id="locSearch" name="location"
                                               className="form-control listing-search where"
                                               placeholder={placeholder_where}
                                               defaultValue={qLocation}
                                               title=""/>
                                    </div>
                                }
                                {!hide_what &&
                                    <div className="form-group col-xs-12 col-sm-12 col-md-8 col-lg-7">
                                        <input onKeyUp={_handleWhat} type="text" name="q" key={'qSearch'} className="form-control"
                                               placeholder={placeholder_what}
                                               defaultValue={qString}/>
                                    </div>
                                }
                                <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-2 align-self-center pr-0">
                                    <button type="submit" className="btn btn-primary border-0">
                                        <i className="fa fa-search"></i> Search
                                    </button>
                                </div>
                                <input type="hidden" key={'lSearch'} idx="lSearch" name="l" value={qLocationId}/>
                                <input type="hidden" key={'rSearch'} idx="rSearch" name="r" value={qAdmin}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {text_below_search && <div className="filter-info-2 text-center">
                <p dangerouslySetInnerHTML={{__html: text_below_search}}/>
            </div>}
        </div>
    )
}
