import React from "react";

const BusinessHoursContainer = ({company}) => {
    if (company.hours.length <= 0) {
        return '';
    }
    return (
        <div className="border border-gray-400 p-3 xs:p-6 my-6">
            <h1 className="text-2xl font-semibold">Business Hours</h1>
            {Object.keys(company?.hours).map((k, i) => {
                let _day = company?.hours[k];
                let closed = (_day?.closed ? _day?.closed : 0);
                let opened_24 = _day?.opened_24 ? _day?.opened_24 : 0;

                const days = {Sat: 'Saturday', Sun: 'Sunday', Mon: 'Monday', Tue: 'Tuesday', Wed: 'Wednesday', Thu: 'Thursday', Fri: 'Friday'};
                return (
                    <div key={i} className={'row'}>
                        <div className="flex justify-between items-center gap-4 my-6">
                            <span className="text-lg font-semibold">{days[k]}</span>
                        </div>
                        <div className="col-7 col-md-7">
                            <span className={"text-secondary " + (closed ? 'red-color' : '')}>
                            {closed ? "Closed" : (opened_24 ? " Open 24 Hours" : _day.from + ' to ' + _day.to)}
                            </span>
                        </div>
                    </div>
                )
            })}
            {/* <div className="flex justify-between items-center gap-4 my-6">
        <h2 className="text-lg font-semibold">Monday</h2>
        <p>9am to 6pm</p>
      </div>
      <div className="flex justify-between items-center gap-4 my-6">
        <h2 className="text-lg font-semibold">Tuesday</h2>
        <p>9am to 6pm</p>
      </div>
      <div className="flex justify-between items-center gap-4 my-6">
        <h2 className="text-lg font-semibold">Wednesday</h2>
        <p>9am to 6pm</p>
      </div>
      <div className="flex justify-between items-center gap-4 my-6">
        <h2 className="text-lg font-semibold">Thursday</h2>
        <p>9am to 6pm</p>
      </div>
      <div className="flex justify-between items-center gap-4 my-6">
        <h2 className="text-lg font-semibold">Friday</h2>
        <p>9am to 6pm</p>
      </div>
      <div className="flex justify-between items-center gap-4 my-6">
        <h2 className="text-lg font-semibold text-secondary">Saturday</h2>
        <p className="text-secondary">Closed</p>
      </div>
      <div className="flex justify-between items-center gap-4 my-6">
        <h2 className="text-lg font-semibold text-secondary">Sunday</h2>
        <p className="text-secondary">Closed</p>
      </div> */}
        </div>
    );
};

export default BusinessHoursContainer;
