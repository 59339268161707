import React from "react";

const AllFilters = () => {
  return (
    <div
      className="grid sm:grid-cols-2 p-6 overflow-y-scroll"
      style={{ maxHeight: "70vh" }}
    >
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
      <div className="flex items-center gap-2 my-2">
        <input type="checkbox" className="w-fit" />
        <p>Catering and Supplies</p>
      </div>
    </div>
  );
};

export default AllFilters;
