import React from "react";
import { Link } from "react-router-dom";

import Button from "../../Shared/Button/Button";
import TextField from "../../Shared/TextField/TextField";

const ChangeAccountInfo = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Business Account Information</h1>
      <form className="mt-6 flex flex-col gap-4">
        <TextField label="Full Name" id="name" type="text" />
        <TextField label="Add your Email" id="email" type="email" />
        <TextField label="Position" id="position" type="text" />
        <TextField label="Current Password" id="password" type="password" />
      </form>
      <div className="flex gap-3 mt-4">
        <Link to="/dashboard">
          <Button
            title="Cancel"
            style={{ backgroundColor: "#F5F7FA" }}
            className="text-gray-700"
          />
        </Link>
        <Button title="Save" />
      </div>
    </div>
  );
};

export default ChangeAccountInfo;
