import React from "react";
import BusinessGallerySlider from "./BusinessGallerySlider";

const BusinessGallery = ({company}) => {
  return (
    <div className="my-8">
      <h1 className="text-3xl font-bold">Popular Listings Near You</h1>
      <div>
        <BusinessGallerySlider company={company}/>
      </div>
    </div>
  );
};

export default BusinessGallery;
