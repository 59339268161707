import React from "react";
import {Link} from "react-router-dom";
import StarRatings from "react-star-ratings";
import {IoCall, IoLocation, IoMail, IoNewspaper} from "react-icons/io5";

const BusinessInfo = ({company}) => {

    return (
        <>
            {company && <section className='functioncall'>
                <div className="py-6 flex flex-col sm:flex-row justify-between gap-4 sm:gap-10">
                    <div>
                        <img src={company.logo_url} alt="" className="business-logo"/>
                    </div>
                    <div className="flex-1">
                        <h1 className="text-2xl md:text-4xl font-bold ">
                            {company.name}
                        </h1>
                        <div className="flex items-center gap-4 font-semibold my-3 md:my-4">
                            <h2 className="text-xl">Rating</h2>
                            {/* {company.rating} */}
                            <div className="flex items-center gap-3">
                                <span> {parseFloat(company.rating) ? company.rating : 'No Reviews yet!'}</span>
                                <StarRatings
                                    rating={parseFloat(company.rating) ? parseFloat(company.rating) : 0}
                                    starRatedColor="#ECC56F"
                                    numberOfStars={5}
                                    starDimension="24px"
                                    starSpacing="5px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <p className="my-3 md:my-4">
                            Is this your business?
                            <Link to="/login">
                                <span className="text-secondary font-semibold"> Claim Now</span>
                            </Link>
                        </p>
                        <div className="flex gap-3 mt-4 md:mt-8 flex-wrap">
                            <button className="flex items-center gap-2 border border-gray-500 py-2 px-4">
                                <IoNewspaper/>
                                <a href="#ReviewForm" className="">
                                    Add a Review
                                </a>
                            </button>
                            <button className="flex items-center gap-2 border border-gray-500 py-2 px-4">
                                <IoCall/>
                                <a href={"tel:" + company.phone}>
                                    Call Now
                                </a>
                            </button>

                            <button className="flex items-center gap-2 border border-gray-500 py-2 px-4">
                                <IoMail/>
                                <a href={"mailto:" + company.email}>
                                    Email
                                </a>
                            </button>
                            <button className="flex items-center gap-2 border border-gray-500 py-2 px-4">
                                <IoLocation/>
                                <a href='#map'>
                                    Get Directions
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </section>}
        </>
    );
};

export default BusinessInfo;
