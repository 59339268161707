import React from "react";

const SideBarOptions = ({ title, active, onClick }) => {
  return (
    <div
      className="py-4 px-6 relative cursor-pointer"
      style={{ backgroundColor: "#F5F7FA" }}
      onClick={onClick}
    >
      <h1 className={active ? "text-primary" : ""}>{title}</h1>
      <div
        className={`absolute top-0 left-0 w-2 h-full ${
          active ? "bg-primary" : ""
        } `}
      ></div>
    </div>
  );
};

export default SideBarOptions;
