import React, {useState} from "react";

import "./TextField.css";

const TextField = ({label, type, name, id, value, required, onChange}) => {
    const [inputClasses, setInputClasses] = useState("");
    const inputBlurHandler = (event) => {
        if (event.target.value) {
            setInputClasses("is-valid");
        } else {
            setInputClasses("");
        }
    };

    return (
        <div className="input-container my-1">
            <input
                type={type}
                id={id}
                value={value}
                name={name}
                onChange={onChange}
                required={required}
                onBlur={inputBlurHandler}
                className={inputClasses}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default TextField;
