import React, {useState} from "react";
import {data_get} from "../../helpers/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {IoEllipsisHorizontalOutline} from "react-icons/io5";

window.$ = window.jQuery = require('jquery');

function countCompanies(category) {
    if (category) {
        let companies_count = parseInt(category.companies_count);
        const children = data_get(category, 'children');

        if (children && children.length) {
            let sum = children.reduce(function (prev, current) {
                return prev + parseInt(current.companies_count)
            }, 0);
            companies_count = companies_count + sum;
        }

        return companies_count;
    } else {
        return 0;
    }
}

function AsPictureIcon(categories, count_categories_listing) {
    const [showMore, setShowMore] = useState(false);

    const showMoreHandler = () => {
        setShowMore((prevValue) => !prevValue);
    };
    return (
        <div className="py-14 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {categories && Object.keys(categories).map((id, key) => {
                const category = categories[id];
                const show_more = (categories.length > 8 && key > 6);
                return (<>
                    <div className={"border border-grey-500 sm:w-64 h-40 flex justify-center items-center flex-col transition-all duration-200 ease-in hover:bg-primary hover:border-primary hover:text-white cursor-pointer " + (show_more ? (showMore ? '' : 'hidden') : '')}>
                        <img src={category.picture}/>
                        <p className="text-xl my-3">{category.name}</p>
                        {count_categories_listing == 1 && <p style={{margin: 0}} className="text-center"> {countCompanies(category)} Listings</p>}
                    </div>
                </>)
            })}
            {categories && categories.length > 8 &&
                <div onClick={showMoreHandler} className="border border-grey-500 sm:w-64 h-40 flex justify-center items-center flex-col transition-all duration-200 ease-in hover:bg-primary hover:border-primary hover:text-white cursor-pointer ">
                    <IoEllipsisHorizontalOutline className="text-3xl"/>
                    <p className="text-xl my-3">{showMore ? "Less" : "More"}</p>
                </div>}
        </div>
    )
}

function AsNormalList(categories, count_categories_listing) {
    const [showMore, setShowMore] = useState(false);

    const showMoreHandler = () => {
        setShowMore((prevValue) => !prevValue);
    };
    return (
        <div className="py-14 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
            {categories && Object.keys(categories).map((id, key) => {
                const category = categories[id];
                return (<>
                    <div className={"items-center hover:text-primary cursor-pointer "}>
                        <p className="text-xl mb-1">{category.name}
                            {count_categories_listing == 0 && <small style={{margin: 0}} className="text-center"> ({countCompanies(category)} Listings)</small>}
                        </p>
                    </div>
                </>)
            })}
        </div>
    )
}

export default function Categories(props) {
    const {widget, query_params} = props;
    let title = data_get(widget, 'title');
    const name = data_get(widget, 'name');
    if (!title) {
        title = name;
    }

    const options = widget?.options ? widget?.options : null;

    const sub_title = data_get(widget, 'sub_title');
    const position = data_get(widget, 'position');

    const hide_title = parseInt(data_get(options, 'hide_title', 0));

    const view_more_btn = parseInt(data_get(options, 'show_view_more_btn', 0));
    const view_more_text = parseInt(data_get(options, 'view_more_text', 0));
    const view_more_link = parseInt(data_get(options, 'view_more_link'));

    const type_of_display = data_get(options, 'type_of_display');
    const count_categories_listing = parseInt(data_get(options, 'count_categories_posts'));
    const show_icon = parseInt(data_get(options, 'show_icon'));
    const max_sub_cats = parseInt(data_get(options, 'max_sub_cats'));
    const onclick_behaviour = data_get(options, 'onclick_behaviour');


    const categories = data_get(widget, 'data.categories');
    const subCategories = data_get(widget, 'data.subCategories');

    console.log(categories, widget);
    if (position !== 'footer') {
        return (
            <div className="container mx-auto pt-20 px-6">
                {!hide_title && <h1 className="text-3xl font-bold">{title}</h1>}

                {type_of_display === 'c_picture_icon' ? AsPictureIcon(categories, count_categories_listing) : ''}

                {['cc_normal_list', 'cc_normal_list_s'].includes(type_of_display) ? AsNormalList(categories, count_categories_listing) : ''}
            </div>
        )
    }
    return (
        <div className="container mx-auto pt-20 px-6">
            <h1 className="text-3xl font-bold">{title}</h1>
            <div className="py-14 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                {categories && Object.keys(categories).map((id, key) => {
                    const category = categories[id];

                    return (<>
                        <div className="border border-grey-500 sm:w-64 h-40 flex justify-center items-center flex-col transition-all duration-200 ease-in hover:bg-primary hover:border-primary hover:text-white cursor-pointer ">
                            <img src={category.picture}/>
                            <p className="text-xl my-3">{category.name}</p>
                        </div>
                    </>)
                })}
            </div>
        </div>
    )
}
