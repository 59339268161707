import React from "react";
import FeaturedBusinessListingCard from "./FeaturedBusinessListingCard";

const FeaturedBusinessListing = () => {
  return (
    <div className="container mx-auto py-6 px-6">
      <h1 className="text-3xl font-bold text-primary">
        Featured Business Listing
      </h1>
      <FeaturedBusinessListingCard />
    </div>
  );
};

export default FeaturedBusinessListing;
