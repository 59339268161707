import React, {useState} from "react";
import {Link} from "react-router-dom";
import {IoAddCircle, IoClose, IoOptions} from "react-icons/io5";

import Modal from "react-modal";

import FeaturedBusinessListing from "../components/FeaturedBusinessListing/FeaturedBusinessListing";
import FiltersSection from "../components/FiltersSection/FiltersSection";
import RestaurantsListings from "../components/RestaurantsListings/RestaurantsListings";
import SearchBar from "../components/SearchBar/SearchBar";
// import SearchCategories from "../components/SearchCategories/SearchCategories";
import Pagination from "../components/Pagination/Pagination";
import Banner from "../components/Banner/Banner";
import Layout from "../components/Layout";

Modal.setAppElement("#root");

const Search = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <Layout page={'search'}>
            <div className="px-6">
                <SearchBar className="mt-20"/>
                {/* <div><SearchCategories /></div> */}
            </div>
            <Banner
                firstTitle="Home"
                secondTitle="Restaurants"
                listings="250 listings in California"
            />
            <div className="container mx-auto flex flex-col xs:flex-row xs:justify-between xs:items-center px-6 pt-8 pb-4 gap-2">
                <div className="hidden sm:block">
                    <h1 className="text-xl font-bold">Filters</h1>
                </div>
                <button
                    className="sm:hidden flex items-center gap-2 border border-gray-400 py-2 px-4 rounded-md w-min"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    <IoOptions/>
                    Filters
                </button>
                <div>
                    <label htmlFor="sort" className="text-lg font-bold">
                        Sort:
                    </label>

                    <select name="sort" id="sort">
                        <option value="option1">Option # 1</option>
                        <option value="option2">Option # 2</option>
                        <option value="option3">Option # 3</option>
                    </select>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => {
                    setIsModalOpen(false);
                }}
                style={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                    },
                    content: {
                        padding: "0",
                    },
                }}
                contentLabel="Example Modal"
            >
                <div className="flex justify-between items-center p-4 border-b border-gray-400">
                    <h1 className="text-2xl font-bold">Filters</h1>
                    <button
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                    >
                        <IoClose className="text-2xl "/>
                    </button>
                </div>
                <div>
                    <FiltersSection/>
                </div>
            </Modal>
            <div className="container mx-auto flex justify-between gap-4">
                <div className="hidden sm:block flex-1 relative">
                    <FiltersSection/>
                </div>
                <div style={{flex: 4}} className="mt-2">
                    <FeaturedBusinessListing/>
                    <RestaurantsListings/>
                </div>
            </div>
            <div className="container mx-auto flex justify-center items-center my-6">
                <Pagination/>
            </div>
            <div className="container mx-auto px-6 py-6">
                <div className="border border-gray-400 p-10 flex flex-col md:flex-row md:justify-between md:items-center gap-3">
                    <div>
                        <h1 className="text-2xl xs:text-4xl font-bold">
                            Can't find the business?
                        </h1>
                        <p className="text-lg my-4">
                            Adding a business to Citylocal is always free.
                        </p>
                    </div>
                    <Link to="/register">
                        <button
                            className="flex items-center gap-2 bg-secondary text-white py-4 px-6 rounded-lg"
                            style={{flex: 1}}
                        >
                            <IoAddCircle className="text-xl"/>
                            ADD BUSINESS
                        </button>
                    </Link>
                </div>
            </div>
        </Layout>
    );
};

export default Search;
