import React, {useState} from "react";
import Button from "../components/Shared/Button/Button";
import TextField from "../components/Shared/TextField/TextField";
import Layout from "../components/Layout";
import axiosConfig from "../helpers/axiosConfig";
import {getErrors} from "../helpers/core";
import Alert from "../components/Shared/Alert/Alert";

const Signup = () => {
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [input, setInput] = useState({company_name: '', email: '', phone: '', term: 0});

    const handleInputChange = (event) => {
        let _target = event.target;
        let _value = _target.type === 'checkbox' ? _target.checked : _target.value;
        let _name = _target.name;
        setInput(prevState => {
            return {...prevState, [_name]: _value}
        });
        console.log(input);
    }

    const handleSubmit = async event => {
        event.preventDefault();
        setError(null);
        setMessage(null);
        console.log(input);
        axiosConfig.post("api/web/register", input).then((response) => {
            if (response.data.success) {
                setMessage(response.data.message);
                setInput({company_name: '', phone: '', email: '', term: 0});
            } else {
                setError(response.data.message);
            }
        }).catch(err => {
            setError(getErrors(err));
        });
    }
    return (
        <Layout page={'register'}>
            <div
                className="container mx-auto flex justify-center items-center"
                style={{minHeight: "85vh"}}>
                <div className="max-w-2xl p-6 sm:p-10 sm:text-left ">
                    <form onSubmit={handleSubmit}>
                        <h1 className="text-4xl font-bold tracking-wide">
                            Hello! Let's get started
                        </h1>
                        <p className="text-lg leading-8 my-5 tracking-wide">
                            By continuing, you agree to CityLocal101 Terms of Service and
                            acknowledge our Privacy Policy.
                        </p>
                        <div className="my-6 flex flex-col gap-6">
                            <TextField
                                label="Add Your Business Name"
                                id="company_name"
                                name="company_name"
                                required
                                type="text"
                                /*value={input.company_name}*/
                                onChange={handleInputChange}
                            />
                            <TextField
                                label="Add Your Email"
                                id="email"
                                name="email"
                                type="email"
                                required
                                /*value={input.email}*/
                                onChange={handleInputChange}
                            />
                            <TextField
                                label="Add Your Phone Number"
                                id="phoneNumber"
                                name="phone"
                                type="text"
                                /*value={input.phone}*/
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="custom-checkbox">
                                <input type="checkbox" className="custom-control-input" name="term" id='terms' checked={input.term} value="1" onChange={handleInputChange}/>
                                <label className="custom-control-label black-color mt-3 mb-3" htmlFor="terms">
                                    <span>I have read and agree to the <a href="/#" className="blue-color">Terms & Conditions</a></span>
                                </label>
                            </div>
                        </div>

                        {error && <div className="grid-cols-12"><Alert variant={'danger'} icon={true} dismissable={true} text={error}/></div>}
                        {message && <div className="grid-cols-12"><Alert variant={'success'} icon={true} dismissable={true} text={message}/></div>}
                        <Button title="Create Lisitng" type={'submit'}/>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default Signup;
