import React from "react";

const DealsCard = ({ image, title, description }) => {
  return (
    <div
      className="flex flex-col xs:flex-row justify-between items-center gap-3 my-8"
      style={{
        backgroundColor: "#F5F7FA",
        boxShadow: "0 0 10px rgba(0,0,0,16%)",
      }}
    >
      <div className="w-full xs:w-auto">
        <img
          src={image}
          alt=""
          className="w-full object-cover xs:w-auto h-52"
        />
      </div>
      <div className="flex-1 p-3">
        <h1 className="text-2xl font-bold">{title}</h1>
        <p className="mt-2">{description}</p>
      </div>
    </div>
  );
};

export default DealsCard;
