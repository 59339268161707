import React from "react";
import {IoFastFood} from "react-icons/io5";
import StarRatings from "react-star-ratings/build/star-ratings";


const FeaturedBusinessCard = ({image, title, rating, address, location, website}) => {
    return (
        <div className="border border-gray-400 relative">
            <div className="absolute top-0 left-0 bg-secondary m-4 py-1 px-3 rounded-xl text-white">
                Featured
            </div>
            <div>
                <img
                    src={image}
                    alt=""
                    className="max-h-md w-full h-72 object-cover lg:w-auto lg:h-auto"
                />
            </div>
            <div className="px-4 py-3">
                <h1 className="text-2xl font-bold text-secondary mb-3">{title}</h1>
                <div className="flex items-center gap-3 font-semibold my-2">
                    <h5>Rating</h5>
                    <h5>{rating}</h5>
                    <div className="flex items-center gap-2">
                        <StarRatings
                            rating={parseFloat(rating) ? parseFloat(rating) : 0}
                            starRatedColor="#ECC56F"
                            numberOfStars={5}
                            starDimension="25px"
                            starSpacing="5px"
                            name='rating'
                        />
                        {/* {Array.from({ length: stars }, (index) => (
              <IoStar key={index} className="text-yellow text-xl" />
            ))} */}
                    </div>
                </div>
                <p className="my-3">{address}</p>
                <a href={website} className=" text-primary">
                    {website}
                </a>
                <div className="mt-4 flex justify-between items-center gap-3">
                    <p>{location}</p>
                    <div className="flex items-center gap-2">
                        <IoFastFood className="text-xl"/>
                        <p>Restaurants</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturedBusinessCard;
