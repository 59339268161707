import React from "react";
import {Link} from 'react-router-dom';
import {data_get} from "../../helpers/core";
import StarRatings from "react-star-ratings/build/star-ratings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {IoLocation} from "react-icons/io5";
import LinkButton from "../Shared/Button/LinkButton";

window.$ = window.jQuery = require('jquery');

export default function Companies(props) {
    const {widget, position, query_params} = props;
    const title = widget?.title ? widget.title : (widget?.name ? widget.name : '');
    const sub_title = widget?.sub_title ? widget?.sub_title : '';
    const options = widget?.options ? widget?.options : null;

    const hide_title = parseInt(data_get(options, 'hide_title', 0));
    const view_more_btn = parseInt(data_get(options, 'show_view_more_btn', 0));
    const view_more_text = parseInt(data_get(options, 'view_more_text', 0));
    const view_more_link = parseInt(data_get(options, 'view_more_link'));

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        draggable: false,
        responsive: [{
            breakpoint: 890,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        }, {
            breakpoint: 610,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        ],
    };

    const companies = (widget?.data?.companies ? widget?.data?.companies : null);
    return (
        <div className="container mx-auto pt-20 px-6">
            <div className="row listings">
                <div className="col-md-12">
                    {sub_title && <div className="sub-title">{sub_title}</div>}
                    <h2 className="text-3xl font-bold">{title}</h2>
                </div>
            </div>
            <div className="my-10 px-3">
                <Slider {...settings}>
                    {companies && companies.map((company, key) => {
                        let location = '';

                        if (company.city) {
                            location += (location ? ', ' : '') + company.city;
                        }

                        if (company.state) {
                            location += (location ? ', ' : '') + company.state;
                        }
                        return (<>
                            <div key={key} className="border border-grey-500 m-3">
                                <div className="w-full">
                                    <img src={company.logo_url} alt={company.name} className="w-full max-h-72 object-cover"/>
                                </div>
                                <div className="p-3">
                                    <h1 className="font-bold text-xl my-2 cursor-pointer">
                                        <Link to={'/biz/' + company.slug}>{company.name}</Link>
                                    </h1>
                                    <div className="flex items-center gap-3 font-semibold">
                                        <h5>Rating</h5>
                                        <h5>{company.rating}</h5>
                                        <div className="flex items-center gap-2">
                                            <StarRatings
                                                rating={parseFloat(company.rating) ? parseFloat(company.rating) : 0}
                                                starRatedColor="#ECC56F"
                                                numberOfStars={5}
                                                starDimension="24px"
                                                starSpacing="0px"
                                                name='rating'
                                            />
                                        </div>
                                    </div>
                                    <p className="my-2">{company.description}</p>
                                </div>
                                <div className="mt-3 border-t border-grey-500 p-4 flex justify-between items-center">
                                    <div className="flex items-center gap-2">
                                        <IoLocation className="text-yellow "/>
                                        <p>{location}</p>
                                    </div>
                                    <p>{company?.opened ? 'Opend Now!' : 'Closed Now!'}</p>
                                </div>
                            </div>
                        </>)
                    })}
                </Slider>
            </div>
            <div className="flex justify-center items-center pt-2">
                <LinkButton to={'/search'}>See All Listings</LinkButton>
            </div>
        </div>
    )
}
