import React, { useState } from "react";
import "./TextArea.css";

const TextArea = ({ label, id, onChange }) => {
  const [inputClasses, setInputClasses] = useState("");
  const inputBlurHandler = (event) => {
    if (event.target.value) {
      setInputClasses("is-valid");
    } else {
      setInputClasses("");
    }
  };
  return (
    <div className="text-container my-1">
      <textarea
        id={id}
        required
        onBlur={inputBlurHandler}
        className={inputClasses}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default TextArea;
