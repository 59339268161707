import React from "react";
import {Link} from "react-router-dom";

const LinkButton = ({text, to, type, onClick, className, style, children}) => {
    return (
        <Link to={to}
              className={`bg-primary text-white font-semibold shadow-sm py-4 px-8 my-2 ${className}`}
              onClick={onClick}
              style={style}
        >
            {children ? children : text}
        </Link>
    );
};

export default LinkButton;
