import React from "react";
import Button from "../../Shared/Button/Button";
import TextField from "../../Shared/TextField/TextField";

const BusinessLocation = () => {
  return (
    <div className="flex flex-col-reverse lg:flex-row justify-between gap-6">
      <div className="flex-1">
        <h1 className="text-2xl font-bold">Add Your Business Location</h1>
        <p className="mt-4 leading-8">
          Start by searching for your business to see if it’s already listed on
          City Local101 Adding a location is completely free and only takes a
          minute.
        </p>
        <form className="flex flex-col gap-4 mt-6">
          <TextField label="Street Address" id="street_address" type="text" />
          <TextField label="City" id="city" type="text" />
          <TextField label="State" id="state" type="text" />
          <TextField label="Zip Code" id="zip_code" type="text" />
        </form>
        <div className="flex gap-3 mt-4">
          <Button
            title="Cancel"
            style={{ backgroundColor: "#F5F7FA" }}
            className="text-gray-700"
          />
          <Button title="Save" />
        </div>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d101408.03263151072!2d-122.15130718904442!3d37.41345079176281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb7495bec0189%3A0x7c17d44a466baf9b!2sMountain%20View%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1658553180755!5m2!1sen!2s"
          height="300"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="w-full"
          title="location_map"
        ></iframe>
      </div>
    </div>
  );
};

export default BusinessLocation;
