import React from "react";
import { Link } from "react-router-dom";

import Button from "../../Shared/Button/Button";
import TextField from "../../Shared/TextField/TextField";

const ChangePassword = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Change Your Password</h1>
      <form className="mt-6 flex flex-col gap-4">
        <TextField
          label="Current Password"
          id="current_password"
          type="password"
        />
        <TextField label="New Password" id="new_password" type="password" />
        <TextField
          label="New Password (again)"
          id="new_password_again"
          type="password"
        />
      </form>
      <div className="flex gap-3 mt-4">
        <Link to="/dashboard">
          <Button
            title="Cancel"
            style={{ backgroundColor: "#F5F7FA" }}
            className="text-gray-700"
          />
        </Link>
        <Button title="Save" />
      </div>
    </div>
  );
};

export default ChangePassword;
