import React from "react";
import StarRatings from "react-star-ratings/build/star-ratings";

function dateFormat(reviewElement) {
    let string = reviewElement.created_at;
    const date = new Date(string);
    const newDate = date.toLocaleString('En-en');
    return newDate;
}

const ReviewCard = (props) => {
    const review = (props.review ? props.review : null);
    return (
        <div style={{boxShadow: "0 0 10px rgba(0,0,0,16%)"}} className="my-8">
            <div
                className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-6 gap-6"
                style={{
                    backgroundColor: "#F5F7FA",
                }}>
                <h1 className="text-6xl text-gray-500">{review.rating}</h1>
                <div className="flex-1">
                    <h1 className="text-xl font-semibold">{review.name}</h1>
                    <p>{dateFormat(review)}</p>
                </div>
                <div className="flex items-center gap-3 font-semibold">
                    <h5>Rating</h5>
                    <h5>{review.rating}</h5>
                    <div className="flex items-center gap-2">
                        <StarRatings
                            rating={review.rating ? parseInt(review.rating) : 0}
                            starRatedColor="#ECC56F"
                            numberOfStars={5}
                            starDimension="24px"
                            starSpacing="5px"
                            name='rating'
                        />
                    </div>
                </div>
            </div>
            <div className="p-6">
                <p className="leading-8 tracking-wide" dangerouslySetInnerHTML={{__html: review.review}}/>
            </div>
        </div>
    );
};

export default ReviewCard;
