import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Button from "../components/Shared/Button/Button";
import TextField from "../components/Shared/TextField/TextField";

const SetPassword = () => {
  return (
    <div>
      <Navbar />
      <div
        className="container mx-auto flex justify-center items-center overflow-y-scroll"
        style={{ minHeight: "85vh" }}
      >
        <div className="max-w-2xl p-6 sm:p-10 text-center sm:text-left ">
          <h1 className="text-4xl font-bold tracking-wide text-secondary">
            Set Your Password
          </h1>
          <p className="text-lg leading-8 my-5 tracking-wide">
            Please enter and confirm your password in the fields below
          </p>
          <div className="my-6 flex flex-col gap-6">
            <TextField label="New Password" id="password" type="password" />
            <TextField
              label="New Password (again)"
              id="again-password"
              type="password"
            />
          </div>

          <div className="my-6">
            <Button title="Set New Password" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
