import React, { useState } from "react";
import Modal from "react-modal";

import { IoStar, IoClose } from "react-icons/io5";
import TextField from "../Shared/TextField/TextField";
import AllFilters from "./AllFilters";
import Button from "../Shared/Button/Button";

Modal.setAppElement("#root");

const FiltersSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="px-6 py-4 sticky top-0 left-0">
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
          content: {
            padding: "0",
            maxWidth: "600px",
            margin: "auto",
            overflow: "hidden",
          },
        }}
        contentLabel="All Filters Modal"
      >
        <div className="flex justify-between items-center p-4 border-b border-gray-400">
          <h1 className="text-2xl font-bold">All Filters</h1>
          <button
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <IoClose className="text-2xl " />
          </button>
        </div>
        <div>
          <AllFilters />
        </div>
        <div className="flex justify-end px-6 py-2">
          <Button
            title="Search"
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
        </div>
      </Modal>
      <div>
        <div className="border-b border-gray-400 py-2">
          <h1 className="font-bold text-lg">Suggested</h1>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Open Now 8:30pm</p>
          </div>
        </div>
        <div className="border-b border-gray-400 py-2 mt-1">
          <h1 className="font-bold text-lg">Ratings</h1>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit " />
            <IoStar className="text-yellow" />
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit " />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit " />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit " />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit " />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
            <IoStar className="text-yellow" />
          </div>
        </div>
        <div className="border-b border-gray-400 py-2 pb-4 mt-1">
          <h1 className="font-bold text-lg mb-3">Location</h1>
          <TextField label="Enter Location" id="location" type="text" />
        </div>
        <div className="border-b border-gray-400 py-2 mt-1">
          <h1 className="font-bold text-lg">Subcategory</h1>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Desserts</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Catering and Supplies</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Fast Food and Takeaway</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Beverage and Tobacco</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Desserts</p>
          </div>
          <button
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="my-2 font-semibold"
          >
            See all
          </button>
        </div>
        <div className="border-b border-gray-400 py-2 mt-1">
          <h1 className="font-bold text-lg">Areas</h1>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Dulwich</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Ealing</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Earls Court</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Earls Field</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>East Action</p>
          </div>
          <button
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="my-2 font-semibold"
          >
            See all
          </button>
        </div>
        <div className="py-2 mt-1">
          <h1 className="font-bold text-lg">Distance</h1>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Within 5 miles</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Within 2 miles</p>
          </div>
          <div className="flex items-center gap-2 my-2">
            <input type="checkbox" className="w-fit" />
            <p>Within 1 miles</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersSection;
