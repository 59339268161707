import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Button from "../components/Shared/Button/Button";
import TextField from "../components/Shared/TextField/TextField";

const ForgotPassword = () => {
  return (
    <div>
      <Navbar />
      <div
        className="container mx-auto flex justify-center items-center overflow-y-scroll"
        style={{ minHeight: "85vh" }}
      >
        <div className="max-w-2xl p-6 sm:p-10 text-center sm:text-left ">
          <h1 className="text-4xl font-bold tracking-wide">Forgot Password</h1>
          <p className="text-lg leading-8 my-5 tracking-wide">
            Please enter your email address associated with your{" "}
            <span className="text-primary">CityLocal101</span>
            for Business account.
          </p>
          <div className="my-6 flex flex-col gap-6">
            <TextField label="Add Your Email" id="email" type="email" />
          </div>

          <Link to="/set-password" className="my-6">
            <Button title="Reset Password" className="w-full" />
          </Link>
          <div className="sm:text-right">
            <Link to="/login">Back to Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
